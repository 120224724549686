import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.main.carousel');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service"

@Component({
    selector: 'wiz-component-main-carousel',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.main.carousel/view.scss */
.carousel {
  display: flex;
  flex-direction: column;
}
.carousel .container {
  flex: 1;
  display: flex;
}
.carousel .carousel-item {
  height: 50vh;
  max-height: 480px;
}
.carousel .carousel-item .carousel-item-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel .carousel-item .carousel-item-inner .carousel-text {
  width: 100%;
  max-width: 1280px;
  padding: 64px;
}
.carousel .carousel-item .carousel-item-inner .carousel-text .carousel-heading {
  font-size: 48px;
  color: #fff;
}
.carousel .carousel-item .carousel-item-inner .carousel-text .carousel-description {
  max-width: 500px;
  color: #fff;
  margin-bottom: 24px;
  font-size: 16px;
}
.carousel .carousel-item .carousel-item-inner .carousel-text .carousel-action .btn {
  width: 180px;
}
.carousel > .container {
  padding-top: 24px;
  padding-bottom: 48px;
}`],
})
export class ComponentMainCarouselComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default ComponentMainCarouselComponent;