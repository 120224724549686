import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.community');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import "../node_modules/ckbox/dist/styles/ckbox.css";

@Component({
    selector: 'wiz-page-community',
template: templateSource || '',
    styles: [``],
})
export class PageCommunityComponent implements OnInit {
    public category: string = null;
    public mode: string = null;
    public id: string = null; public title: string = "";
    public category_list = [{ id: "notice", name: "공지사항" }, { id: "issue", name: "이슈 및 추천게시판" }, { id: "qna", name: "문의게시판" }];


    constructor(@Inject( Service)         public service: Service,@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Router)         public router: Router,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.setting();
        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                if (this.router.url.split('/')[1] == 'community')
                    // Hide progress spinner or progress bar
                    await this.setting();
            }
        })
        await this.service.render();
    }
    public async setting() {
        this.category = WizRoute.segment.category;
        this.mode = WizRoute.segment.mode;
        this.id = this.route.params.id || null; this.title = this.category_list.find(e => e.id === this.category).name
        // console.log("community change")
    }
}

export default PageCommunityComponent;