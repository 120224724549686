import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.nav');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-nav',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/layout.nav/view.scss */
.modal-blur {
  backdrop-filter: blur(4px);
}

.wiz-nav-page {
  display: flex;
  flex-direction: column;
}
.wiz-nav-page header.navbar {
  background: var(--wiz-color-background);
  min-height: auto;
  border-bottom: 1px solid var(--wiz-color-border);
  padding-top: 12px;
  padding-bottom: 12px;
}
.wiz-nav-page header.navbar .container {
  display: block;
}
.wiz-nav-page header.navbar .top-menu {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 4px;
}
.wiz-nav-page header.navbar .top-menu > * {
  margin-left: 12px;
  font-size: 14px;
  color: var(--wiz-color-secondary);
  line-height: 1;
}
.wiz-nav-page header.navbar .top-menu .dropdown .dropdown-toggle {
  font-size: 13px;
  padding: 0 12px;
  height: 21px;
}
.wiz-nav-page header.navbar .main-menu {
  display: flex;
  justify-content: space-between;
}
.wiz-nav-page header.navbar .main-menu .navbar-brand img {
  height: 40px;
}
.wiz-nav-page header.navbar .main-menu .navbar-menu {
  display: flex;
  flex-direction: column;
  height: auto;
  flex: auto;
  align-items: flex-end;
}
.wiz-nav-page header.navbar .main-menu .navbar-menu .nav-item {
  font-size: 18px;
  min-width: 120px;
}
.wiz-nav-page header.navbar .main-menu .navbar-menu .nav-item .nav-link {
  padding: 6px 16px;
}
.wiz-nav-page header.navbar .main-menu .navbar-menu .nav-item .nav-link .nav-link-title {
  color: var(--wiz-color-text);
}
.wiz-nav-page header.navbar .main-menu .navbar-menu .nav-item.active {
  margin-left: 6px;
  margin-right: 6px;
}
.wiz-nav-page header.navbar .main-menu .navbar-menu .nav-item.active .nav-link {
  font-weight: 1000;
  font-family: "wiz-eb";
}
.wiz-nav-page header.navbar .main-menu .navbar-menu .nav-item.active .nav-link .nav-link-title {
  color: var(--wiz-color-blue);
}
.wiz-nav-page header.navbar .main-menu .navbar-menu .nav-items-top {
  display: none;
}
.wiz-nav-page > .wiz-view {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow: auto;
  background: var(--wiz-color-background-lt);
}
.wiz-nav-page > .wiz-view > * {
  flex: auto;
}
.wiz-nav-page > .wiz-view .container {
  display: flex;
  overflow: hidden;
  padding: 0;
  height: 100%;
}
.wiz-nav-page > .wiz-view .container > * {
  padding: 24px 12px;
}
.wiz-nav-page > .wiz-view .container > .aside-menu {
  display: flex;
  flex-direction: column;
  width: 280px;
  overflow: hidden;
}
.wiz-nav-page > .wiz-view .container > .aside-menu .card {
  height: auto;
  overflow: hidden;
}
.wiz-nav-page > .wiz-view .container > .aside-menu .card-menu {
  padding: 0;
  flex: auto;
  overflow: auto;
}
.wiz-nav-page > .wiz-view .container > .aside-menu .card-menu .card-body {
  font-size: 16px;
  padding: 0;
  color: var(--wiz-color-text) !important;
}
.wiz-nav-page > .wiz-view .container > .aside-menu .card-menu .card-body a {
  display: block;
  height: 100%;
  padding: 12px 16px;
  color: var(--wiz-color-text) !important;
}
.wiz-nav-page > .wiz-view .container > .aside-menu .card-menu .card-body.active {
  background: var(--wiz-color-blue-lt);
  color: var(--wiz-color-blue) !important;
}
.wiz-nav-page > .wiz-view .container > .aside-menu .card-menu .card-body.active a {
  color: var(--wiz-color-blue) !important;
}
.wiz-nav-page > .wiz-view .container > .wiz-content {
  flex: 1;
  overflow: auto;
}
.wiz-nav-page > .wiz-view .wiz-footer {
  flex: none;
  color: #888;
  background: #3a3a3a;
  font-size: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
}
.wiz-nav-page > .wiz-view .wiz-footer small {
  font-size: 14px;
}
.wiz-nav-page > .wiz-view .wiz-footer a {
  color: #888;
}
.wiz-nav-page > .wiz-view .wiz-footer a.color {
  color: var(--wiz-color-red);
  cursor: pointer;
}

@media (max-width: 768px) {
  .wiz-nav-page > header.navbar .main-menu {
    display: block;
  }
  .wiz-nav-page > header.navbar .main-menu .navbar-toggler {
    display: inline-block;
    margin-right: 12px;
  }
  .wiz-nav-page > header.navbar .main-menu .navbar-brand {
    display: inline-block;
  }
  .wiz-nav-page > header.navbar .main-menu .navbar-menu {
    display: block;
  }
  .wiz-nav-page > header.navbar .main-menu .navbar-menu .nav-items-top {
    display: block;
    border-top: 1px solid var(--wiz-color-border);
    margin-top: 12px;
    padding-top: 8px;
  }
}`],
})
export class LayoutNavComponent implements OnInit {
    @Input() title: any;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default LayoutNavComponent;