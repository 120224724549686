import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.user.top');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Menu } from "src/libs/menu";
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
    selector: 'wiz-component-user-top',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.user.top/view.scss */
.top-menu {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
}
.top-menu > * {
  margin-left: 12px;
  font-size: 14px;
  color: var(--wiz-color-secondary);
  line-height: 1;
}
.top-menu .dropdown .dropdown-toggle {
  font-size: 13px;
  padding: 0 12px;
  height: 21px;
}

.navbar-menu {
  display: flex;
  flex-direction: column;
  height: auto;
  flex: auto;
  align-items: flex-end;
}
.navbar-menu .nav-item {
  font-size: 18px;
  min-width: 120px;
}
.navbar-menu .nav-item .nav-link {
  padding: 6px 16px;
}
.navbar-menu .nav-item .nav-link .nav-link-title {
  color: var(--wiz-color-text);
}
.navbar-menu .nav-item .nav-link.check {
  font-weight: 1000;
  font-family: "wiz-eb";
}
.navbar-menu .nav-item .nav-link.check .nav-link-title {
  color: var(--wiz-color-blue);
}
.navbar-menu .nav-items-top {
  display: none;
}

.active {
  color: var(--wiz-color-blue);
}`],
})
export class ComponentUserTopComponent implements OnInit {
    public top: [];
    public list: [];
    public url: string = null;
    public logout: boolean = false;

    constructor(@Inject( Service)         public service: Service,@Inject( Menu)         public menu: Menu,@Inject( Router)         public router: Router,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.setting();
        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                await this.setting();
            }
        })
    }

    public async setting() {
        let top: [] = [];
        if (this.service.auth.show("admin")) {
            top.push({ title: "개발자", url: "/developer/info/basic" })
            top.push({ title: "관리자", url: "/admin/config/category" })
        } else if (this.service.auth.show("developer")) {
            top.push({ title: "개발자", url: "/developer/info/basic" })
        }
        if (this.service.auth.show(false)) {
            top.push({ title: "로그인", url: "/login" })
        } else {
            this.logout = true;
        }
        this.top = top;
        this.url = WizRoute.path;
        await this.category();
    }

    public async category() {
        const currentRoute = this.url.split("/");
        let role = currentRoute[0]
        if ((role !== "admin") && (role !== "developer")) {
            role = "user";
        }
        this.list = this.menu[role]
        await this.service.render();
    }

    public isActive(link: string) {
        if (location.pathname.split("/")[1] == "community")
            return link.startsWith("/community");
        if (location.pathname.split("/")[1] == "mypage")
            return link.startsWith("/mypage");
        if (location.pathname.split("/")[1] == "apps")
            return link.startsWith("/apps");
        // console.log(link.startsWith(location.pathname.split("/").splice(0, 3).join("/")), link, location.pathname.split("/").splice(0, 3).join("/"));
        return link.startsWith(location.pathname.split("/").splice(0, 3).join("/"));
    }
}

export default ComponentUserTopComponent;