import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.pending');
import { OnInit, Input, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-pending',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.admin.pending/view.scss */
.apps-pending {
  width: 100%;
  overflow: hidden;
  display: flex;
  max-height: 100%;
}
.apps-pending .list-group {
  flex: auto;
  overflow: auto;
}
.apps-pending .list-group-item {
  display: flex;
  background-color: white;
}
.apps-pending .list-group-item:hover {
  background-color: rgba(27, 125, 241, 0.02);
}
.apps-pending .list-group-item .featured-image {
  width: 200px;
  height: 150px;
}
.apps-pending .list-group-item .app-info {
  flex: 1;
  overflow: hidden;
  padding: 12px 16px;
  margin-left: 16px;
}`],
})
export class ComponentAdminPendingComponent implements OnInit {
    @ViewChild("judge") judge;
    @ViewChild("log") log;
    @Input() title: any;
    public list = [];
    public page = {
        start: 1,
        end: 1,
        current: 1,
    };
    public query = { text: "" };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.pageLoad(1);
        await this.service.render();
    }

    private async pageLoad(p: number) {
        this.page.current = p;
        this.load(this.page.current);
        await this.service.render();
    }

    public async search() {
        this.query.page = 1;
        this.load(this.query.page);
    }

    public async showJudge(id) {
        this.appLoadById(id);
        this.judge.isshow = true;
        console.log(this.judge.app)
        await this.service.render();
    }

    public async showLog(id) {
        await this.logLoadById(id);
        this.log.isshow = true;
        await this.service.render();
    }

    public async load(page) {
        const query = { ...this.query, page, };
        const { code, data } = await wiz.call("apps", query);
        if (code !== 200) {
            await this.service.alert.show("ERROR: Apps load failed");
            await this.service.loading.hide();
            throw new Error("get apps failed");
        }
        const { list, lastpage } = data;
        this.page.start = (parseInt(this.page.current / 11) * 10) + 1;
        this.page.end = lastpage;
        this.list = list;
        await this.service.render();
    }

    public async appLoadById(id) {
        const app = this.list.find(item => item.id === id);
        this.judge.app = app;
        await this.service.render();
    }

    public async logLoadById(id) {
        const { code, data } = await wiz.call("log", { id });
        if (code !== 200) {
            await this.service.alert.show("ERROR: Log load failed");
            return;
        }
        this.log.logs = data;
    }
}

export default ComponentAdminPendingComponent;