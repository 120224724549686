import '@angular/compiler';
import { ComponentDeveloperListComponent } from './component.developer.list/component.developer.list.component';
import { ComponentDeveloperNewComponent } from './component.developer.new/component.developer.new.component';
import { PortalIdePageIdeComponent } from './portal.ide.page.ide/portal.ide.page.ide.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { ComponentAdminPendingComponent } from './component.admin.pending/component.admin.pending.component';
import { PageStoreComponent } from './page.store/page.store.component';
import { PortalIdeSystemEditorComponent } from './portal.ide.system.editor/portal.ide.system.editor.component';
import { ComponentDeveloperShareComponent } from './component.developer.share/component.developer.share.component';
import { PageDeveloperComponent } from './page.developer/page.developer.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { ComponentModalAppJudgeComponent } from './component.modal.app.judge/component.modal.app.judge.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { LayoutDeveloperComponent } from './layout.developer/layout.developer.component';
import { PortalIdeLayoutNoneComponent } from './portal.ide.layout.none/portal.ide.layout.none.component';
import { ComponentMainListComponent } from './component.main.list/component.main.list.component';
import { ComponentMainCarouselComponent } from './component.main.carousel/component.main.carousel.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { ComponentDeveloperAppComponent } from './component.developer.app/component.developer.app.component';
import { ComponentAdminAsideComponent } from './component.admin.aside/component.admin.aside.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { ComponentMypageDevicesComponent } from './component.mypage.devices/component.mypage.devices.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { ComponentAdminAppsComponent } from './component.admin.apps/component.admin.apps.component';
import { ComponentAdminConfigSystemComponent } from './component.admin.config.system/component.admin.config.system.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalIdeAppPreviewComponent } from './portal.ide.app.preview/portal.ide.app.preview.component';
import { ComponentCommunityPostComponent } from './component.community.post/component.community.post.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { ComponentMainRankingComponent } from './component.main.ranking/component.main.ranking.component';
import { ComponentDetailReviewComponent } from './component.detail.review/component.detail.review.component';
import { PortalIdeAppNpmComponent } from './portal.ide.app.npm/portal.ide.app.npm.component';
import { ComponentAdminUsersComponent } from './component.admin.users/component.admin.users.component';
import { PortalIdeEditorMonacoComponent } from './portal.ide.editor.monaco/portal.ide.editor.monaco.component';
import { PortalIdeAppRouteComponent } from './portal.ide.app.route/portal.ide.app.route.component';
import { ComponentUserTopComponent } from './component.user.top/component.user.top.component';
import { ComponentAdminConfigComponent } from './component.admin.config/component.admin.config.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { ComponentDeveloperSharedComponent } from './component.developer.shared/component.developer.shared.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { ComponentDetailAppComponent } from './component.detail.app/component.detail.app.component';
import { PageMainComponent } from './page.main/page.main.component';
import { LayoutNoneComponent } from './layout.none/layout.none.component';
import { ComponentDeveloperInfoComponent } from './component.developer.info/component.developer.info.component';
import { PortalIdeAppExploreComponent } from './portal.ide.app.explore/portal.ide.app.explore.component';
import { PageCommunityComponent } from './page.community/page.community.component';
import { LayoutNavComponent } from './layout.nav/layout.nav.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { ComponentMypageInfoComponent } from './component.mypage.info/component.mypage.info.component';
import { ComponentDeveloperAsideComponent } from './component.developer.aside/component.developer.aside.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { ComponentCommunityEditComponent } from './component.community.edit/component.community.edit.component';
import { ComponentUserAsideComponent } from './component.user.aside/component.user.aside.component';
import { PortalIdeSystemLeftmenuComponent } from './portal.ide.system.leftmenu/portal.ide.system.leftmenu.component';
import { ComponentAdminDevsComponent } from './component.admin.devs/component.admin.devs.component';
import { PageDetailComponent } from './page.detail/page.detail.component';
import { ComponentMypageAppsComponent } from './component.mypage.apps/component.mypage.apps.component';
import { ComponentDeveloperProcessComponent } from './component.developer.process/component.developer.process.component';
import { PortalIdeAppShortcutComponent } from './portal.ide.app.shortcut/portal.ide.app.shortcut.component';
import { PortalIdeEditorInfoComponent } from './portal.ide.editor.info/portal.ide.editor.info.component';
import { PageIdeComponent } from './page.ide/page.ide.component';
import { ComponentCardComponent } from './component.card/component.card.component';
import { PortalIdeAppDeployComponent } from './portal.ide.app.deploy/portal.ide.app.deploy.component';
import { ComponentModalAppLogsComponent } from './component.modal.app.logs/component.modal.app.logs.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { ComponentAdminUpdatingComponent } from './component.admin.updating/component.admin.updating.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { ComponentCommunityListComponent } from './component.community.list/component.community.list.component';
import { LayoutUserComponent } from './layout.user/layout.user.component';
import { ComponentAdminConfigRecommendComponent } from './component.admin.config.recommend/component.admin.config.recommend.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { MatTreeModule } from '@angular/material/tree';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";


@NgModule({
    declarations: [
        AppComponent,
        ComponentDeveloperListComponent,
        ComponentDeveloperNewComponent,
        PortalIdePageIdeComponent,
        PageLoginComponent,
        ComponentAdminPendingComponent,
        PageStoreComponent,
        PortalIdeSystemEditorComponent,
        ComponentDeveloperShareComponent,
        PageDeveloperComponent,
        PortalSeasonLoadingComponent,
        ComponentModalAppJudgeComponent,
        PageAdminComponent,
        LayoutDeveloperComponent,
        PortalIdeLayoutNoneComponent,
        ComponentMainListComponent,
        ComponentMainCarouselComponent,
        PortalSeasonLoadingHexaComponent,
        ComponentDeveloperAppComponent,
        ComponentAdminAsideComponent,
        ComponentNavComponent,
        ComponentMypageDevicesComponent,
        PortalSeasonLoadingSeasonComponent,
        ComponentAdminAppsComponent,
        ComponentAdminConfigSystemComponent,
        PortalSeasonPagenationComponent,
        PortalIdeAppPreviewComponent,
        ComponentCommunityPostComponent,
        PortalSeasonStatusbarComponent,
        ComponentMainRankingComponent,
        ComponentDetailReviewComponent,
        PortalIdeAppNpmComponent,
        ComponentAdminUsersComponent,
        PortalIdeEditorMonacoComponent,
        PortalIdeAppRouteComponent,
        ComponentUserTopComponent,
        ComponentAdminConfigComponent,
        PortalSeasonViewerTreeComponent,
        ComponentDeveloperSharedComponent,
        PortalSeasonUiDropdownComponent,
        ComponentDetailAppComponent,
        PageMainComponent,
        LayoutNoneComponent,
        ComponentDeveloperInfoComponent,
        PortalIdeAppExploreComponent,
        PageCommunityComponent,
        LayoutNavComponent,
        LayoutNavbarComponent,
        PortalSeasonAlertComponent,
        ComponentMypageInfoComponent,
        ComponentDeveloperAsideComponent,
        PageMypageComponent,
        ComponentCommunityEditComponent,
        ComponentUserAsideComponent,
        PortalIdeSystemLeftmenuComponent,
        ComponentAdminDevsComponent,
        PageDetailComponent,
        ComponentMypageAppsComponent,
        ComponentDeveloperProcessComponent,
        PortalIdeAppShortcutComponent,
        PortalIdeEditorInfoComponent,
        PageIdeComponent,
        ComponentCardComponent,
        PortalIdeAppDeployComponent,
        ComponentModalAppLogsComponent,
        LayoutAdminComponent,
        ComponentAdminUpdatingComponent,
        PortalSeasonTabComponent,
        ComponentCommunityListComponent,
        LayoutUserComponent,
        ComponentAdminConfigRecommendComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            defaultLanguage: 'ko'
        }),
                KeyboardShortcutsModule,
        MatTreeModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}
