import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.mypage.apps');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-mypage-apps',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.mypage.apps/view.scss */
.introduction {
  min-height: 500px;
}

.list-group-item {
  background-color: white;
}
.list-group-item:hover {
  background-color: rgba(27, 125, 241, 0.02);
}

.form-selectgroup-label {
  color: #C8C8C8;
  border: none;
}
.form-selectgroup-label:focus {
  box-shadow: none;
  border-color: white;
}
.form-selectgroup-label:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: white;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  color: var(--wiz-color-blue);
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
}`],
})
export class ComponentMypageAppsComponent implements OnInit {
    @Input() list: any;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.loading.show();
        await this.load();
        await this.service.loading.hide();
    }

    public async load() {
        const { code, data } = await wiz.call("apps");
        if (code !== 200) {
            alert("ERROR: Apps load failed");
            await this.service.loading.hide();
            throw new Error("get apps failed");
        }
        this.list = data;
        await this.service.render();
    }

    public async alert(title: string, message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async delete(id) {
        let res = await this.alert("앱 삭제", "앱을 정말 삭제하시겠습니까?", "error", "취소", "삭제");
        if (res) {
            await this.service.loading.show();
            const { code } = await wiz.call("uninstall", { id: id });
            await this.service.loading.hide();
            if (code !== 200) {
                alert("ERROR: uninstall failed");
                return;
            }
            alert("삭제가 완료되었습니다.");
            await this.load();
        }
    }
}

export default ComponentMypageAppsComponent;