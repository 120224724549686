import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.users');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-users',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.admin.users/view.scss */
.current {
  background-color: var(--wiz-color-light);
}`],
})
export class ComponentAdminUsersComponent implements OnInit {
    public role = WizRoute.segment.mode;
    public title = { 'admin': "관리자", 'developer': "개발자", 'user': "사용자", 'all': "전체" };
    public list = [];
    public page = {
        start: 1,
        end: 1,
        current: 1,
    };
    public query = { page: 1, text: "", role: this.role };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.pageLoad(1);
        await this.service.render();
    }

    private async pageLoad(p: number) {
        this.page.current = p;
        this.load(this.page.current);
        await this.service.render();
    }

    public async load(page) {
        const query = { ...this.query, role: this.role, page, };
        let pd = query;

        let { code, data } = await wiz.call("search", pd)
        if (code === 200) {
            const { list, lastpage } = data;
            this.page.start = (parseInt(this.page.current / 11) * 10) + 1;
            this.page.end = lastpage;
            this.list = list;
            await this.service.render();
        }
    }

    public async search() {
        this.query.page = 1;
        this.load(this.query.page);
    }

    public async change_role(item) {
        let user = item;
        await wiz.call("update", user)
    }
}

export default ComponentAdminUsersComponent;