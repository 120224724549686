import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.ide.app.npm');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/ide/service";

@Component({
    selector: 'wiz-portal-ide-app-npm',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/portal.ide.app.npm/view.scss */
.side-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.side-heading {
  display: flex;
  padding: 8px 16px;
  background: var(--wiz-color-blue-lt);
  color: var(--wiz-color-blue);
}
.side-heading .actions {
  margin-left: auto;
}
.side-heading .actions > * {
  margin-left: 6px;
  min-width: 24px;
  height: 24px;
}

.side-search {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid var(--wiz-color-border);
}
.side-search label.form-check.form-switch {
  margin: 0;
}

.side-body {
  flex: auto;
  width: 100%;
  overflow: auto;
  padding: 0;
}
.side-body tr,
.side-body td {
  vertical-align: middle;
  word-break: break-all;
}`],
})
export class PortalIdeAppNpmComponent implements OnInit {
    public base;
    public keyword: string = "";
    public data: any = [];
    public required: Array<string> = [
        "@angular/animations",
        "@angular/common",
        "@angular/compiler",
        "@angular/core",
        "@angular/forms",
        "@angular/platform-browser",
        "@angular/platform-browser-dynamic",
        "@angular/router",
        "jquery",
        "ngc-esbuild",
        "pug",
        "rxjs",
        "socket.io-client",
        "tslib",
        "zone.js"
    ];

    constructor(@Inject( Service) public service: Service) {
        this.base = this.service.base;
    }

    public async ngOnInit() {
        await this.service.loading.show();
        await this.load();
        await this.service.loading.hide();
    }

    public async load() {
        let { data } = await wiz.call("list", { path: this.base });
        data.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        this.data = data;
    }

    public match(value: string) {
        if (value.toLowerCase().indexOf(this.keyword.toLowerCase()) >= 0)
            return true;
        return false;
    }

    public async install(keyword: string) {
        if (!keyword) return;
        await this.service.loading.show();
        let { data } = await wiz.call("install", { package: keyword, path: this.base });
        this.service.log(data);
        await this.load();
        await this.service.loading.hide();
    }

    public async uninstall(keyword: string) {
        if (!keyword) return;
        await this.service.loading.show();
        let { data } = await wiz.call("uninstall", { package: keyword, path: this.base });
        this.service.log(data);
        await this.load();
        await this.service.loading.hide();
    }

}

export default PortalIdeAppNpmComponent;