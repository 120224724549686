import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
    selector: 'wiz-page-admin',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/page.admin/view.scss */
/* src/app/page.admin/view.scss: no such file or directory */`],
})
export class PageAdminComponent implements OnInit {
    public category: string = null;
    public mode: string = null;

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         public router: Router,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        this.category = WizRoute.segment.category;
        this.mode = WizRoute.segment.mode;
        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                this.category = WizRoute.segment.category;
                this.mode = WizRoute.segment.mode;
            }
        })
    }
}

export default PageAdminComponent;