import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.developer.process');
import { OnInit, Input, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-developer-process',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.developer.process/view.scss */
/* src/app/component.developer.process/view.scss: no such file or directory */`],
})
export class ComponentDeveloperProcessComponent implements OnInit {
    @ViewChild("log") log;
    @Input() title: any;
    public app_id = WizRoute.path.split("id=")[1]
    public data = {};
    public versions = [];
    public request_version = null;
    public status = "new";

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.deploy_list();
        await this.service.render();
    }

    public async showLog(id) {
        await this.logLoadById(id);
        this.log.isshow = true;
        await this.service.render();
    }

    public async load() {
        const app_id = this.app_id;
        let { code, data } = await wiz.call("info", { app_id });
        if (code !== 200) {
            location.href = "/developer";
            return;
        }
        if (data.request_version) {
            this.request_version = data.request_version;
            this.status = "proceed";
            delete data.request_version;
        }
        else if (data.status === "pending") {
            this.status = "pending";
        }
        else if (data.status === "publish") {
            this.status = "publish";
        }
        this.data = data;
    }

    public async deploy_list() {
        const unique_key = this.app_id;
        const { code, data } = await wiz.call("list", { unique_key });
        if (code !== 200) {
            toastr.error("Failed");
            return;
        }
        this.versions = data.filter(item => item.id !== "dev");
    }

    public async validation() {
        const { request_version, data } = this;
        const { icon, thumbnail, } = data;

        if (!request_version) {
            await this.service.alert.error("배포 요청할 버전을 선택해주세요.");
            return false;
        }

        if (!icon) {
            await this.service.alert.error("아아콘을 업로드해주세요.");
            return false;
        }

        if (!thumbnail) {
            await this.service.alert.error("Featured Image를 업로드해주세요.");
            return false;
        }

        return true;
    }

    public async request() {
        const app_id = this.app_id;
        const check = await this.validation();
        if (!check) return;
        const isnew = this.status === "new" ? true : false;
        const version = this.request_version;
        const res = await this.service.alert.show({
            message: `"${version}" 버전으로 ${isnew ? "앱 등록을" : "업데이트를"} 요청하시겠습니까?`,
            status: "btn-primary",
            action: '진행',
            cancel: "닫기",
            title: "배포 요청 확인",
        });
        if (!res) return;

        const data = {
            app_id,
            version,
            status: this.status,
        };
        await this.service.loading.show();
        const { code } = await wiz.call("request", data);
        await this.service.loading.hide();

        if (code === 200) {
            await this.service.alert.success("배포 요청을 완료하였습니다.", { btn_class: 'btn-primary', btn_action: '닫기', btn_close: null, title: '배포 요청 완료' });
            location.reload();
            return;
        }

        await this.service.alert.error("배포 요청을 실패하였습니다. 관리자에게 문의하세요.");
    }

    public async logLoadById(id) {
        const { code, data } = await wiz.call("log", { id });
        if (code !== 200) {
            await this.service.alert.error("ERROR: Log load failed");
            return;
        }
        this.log.logs = data;
    }
}

export default ComponentDeveloperProcessComponent;