import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.detail.app');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-detail-app',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.detail.app/view.scss */
.app-title {
  font-size: 32px;
  line-height: 40px;
}

.sticky {
  position: sticky;
  z-index: 10;
  background: white;
}

.img-responsive {
  display: block;
  width: 100%;
  max-height: 400px;
  padding: 0;
  background: no-repeat center/contain;
}

.app-desc {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  margin-top: 15px;
  font-size: 15px;
}`],
})
export class ComponentDetailAppComponent implements OnInit {
    @Input() app: any;
    private extensionInstalled = false;
    private retry = 5;
    private id = null;
    private info = {};

    public async ngOnInit() {
        await this.service.init();
        this.checkExtension();
        await this.service.render();
    }

    constructor(@Inject( Service)         public service: Service,    ) { }

    public checkExtension() {
        setTimeout(async () => {
            const chk = document.querySelector("iframe#digital-on") ? true : true;
            this.extensionInstalled = chk;
            if (!chk && retry > 0) {
                retry--;
                checkExtension();
                return;
            }
            await this.service.render();
        }, 600);
    }

    public async install() {
        // await this.service.loading.show();
        const { code } = await wiz.call("install", { id: this.app.info.id });
        // await this.service.loading.hide();
        if (code !== 200) {
            alert("ERROR: Install app failed");
            return;
        }
        location.reload();
    }
    public async remove() {
        // await this.service.loading.show();
        const { code } = await wiz.call("remove", { id: this.app.info.id });
        // await this.service.loading.hide();
        if (code !== 204) {
            alert("ERROR: Remove app failed");
            return;
        }
        location.reload();
    }
}

export default ComponentDetailAppComponent;