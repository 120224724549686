import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.ide.app.deploy');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/ide/service";


@Component({
    selector: 'wiz-portal-ide-app-deploy',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/portal.ide.app.deploy/view.scss */
.side-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.side-heading {
  display: flex;
  padding: 8px 16px;
  background: var(--wiz-color-blue-lt);
  color: var(--wiz-color-blue);
  border-bottom: 1px solid var(--wiz-color-border);
}

.side-search {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid var(--wiz-color-border);
}
.side-search label.form-check.form-switch {
  margin: 0;
}

.side-body {
  flex: auto;
  width: 100%;
  overflow: auto;
  padding: 0;
}
.side-body tr,
.side-body td {
  vertical-align: middle;
}

.side-search > * {
  height: 24px;
  font-size: 12px;
}`],
})
export class PortalIdeAppDeployComponent implements OnInit {
    public unique_key;
    public version;

    public keyword: string = "";
    public list: any = [];
    // public isdev = wiz.dev();

    constructor(@Inject( Service) private service: Service) {
        this.unique_key = this.service.unique_key;
        this.version = this.service.version
    }

    public async ngOnInit() {
        await this.service.loading.show();
        await this.load();
        await this.service.loading.hide();
    }

    public async load() {
        let { code, data } = await wiz.call("list", { unique_key: this.unique_key });
        if (code !== 200) return;
        data.sort((a, b) => b.id.localeCompare(a.id));
        this.list = data;
    }

    public async download(item: any) {
        let target = wiz.url('download?unique_key=' + this.unique_key + "&version=" + item.id);
        window.open(target, '_blank');
    }

    public async rebuild(item: any) {
        await this.service.loading.show();
        let path = "/var/www/digitalon/storage/" + this.unique_key + "/apps/" + item.id;
        await wiz.call("rebuild", { unique_key: this.unique_key, path });
        await this.service.loading.hide();
    }

    public async remove(item: any) {
        let res = await this.service.alert.show({ title: 'Delete', message: `Are you sure to delete '${item.id}' project?`, action_text: "Delete", action_class: "btn-danger", cancel: "cancel" });
        if (!res) return;

        await this.service.loading.show();
        await wiz.call("delete", { unique_key: this.unique_key, path: item.id });
        await this.load();
        await this.service.loading.hide();
    }

    public async deploy() {
        if (!/^\d{1,}[\d\.]{1,}\d{1,}$/.test(this.keyword)) {
            await this.service.alert.error("deploy version은 숫자와 .만 허용되며, 숫자로 시작하여 숫자로 끝나야 합니다.");
            return;
        }
        const versions = this.list.map(it => it.name);
        if (versions.includes(this.keyword)) {
            await this.service.alert.error("이미 존재하는 버전입니다.");
            return;
        }
        await this.service.loading.show();
        let path = "/var/www/digitalon/storage/" + this.unique_key + "/apps";
        const { code, data } = await wiz.call("deploy", {
            unique_key: this.unique_key,
            version: this.keyword,
            path,
        });
        await this.service.loading.hide();
        if (code !== 200) {
            await this.service.alert.error("배포 실패!");
            return;
        }
        await this.load();
        this.service.render();
    }

}

export default PortalIdeAppDeployComponent;