import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.developer.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Menu } from "src/libs/menu";

@Component({
    selector: 'wiz-component-developer-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.developer.list/view.scss */
.list {
  width: 100%;
  overflow: hidden;
  display: flex;
  max-height: 100%;
}
.list .list-group {
  flex: auto;
  overflow: auto;
}
.list .list-group-item {
  display: flex;
  background-color: white;
}
.list .list-group-item:hover {
  background-color: rgba(27, 125, 241, 0.02);
}
.list .list-group-item .featured-image {
  width: 200px;
  height: 150px;
}
.list .list-group-item .app-info {
  flex: 1;
  overflow: hidden;
  padding: 12px 16px;
  margin-left: 16px;
}`],
})
export class ComponentDeveloperListComponent implements OnInit {
    @Input() type: any;
    public list = [];
    public status = "all";
    public url;
    public id: string;

    constructor(@Inject( Service)         public service: Service,@Inject( Menu)         public menu: Menu,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public async load() {
        let body = {};
        if (this.type) this.load = "share";
        if (this.status !== "all") body.status = this.status;
        const { code, data } = await wiz.call("load", body);
        if (code !== 200) {
            await this.service.alert.show(`ERROR: ${data}`);
            throw new Error("App list load failed");
        }
        this.list = data;
        await this.service.render();
    }

    public async apply(id) {
        const { code } = await wiz.call("apply", { id });
        if (code !== 200) {
            await this.service.alert.show("ERROR: Apply judge failed");
            return;
        }
        await this.service.alert.show("승인신청이 완료되었습니다.");
        await this.load();
    }

    public async publish(id, status) {
        const { code } = await wiz.call("publish", { id, status });
        if (code !== 200) {
            await this.service.alert.show("ERROR: publish failed");
            return;
        }
        // await this.service.alert.show(`${{ "active": "배포가", "inactive": "비활성이", "private": "삭제가" }} 완료되었습니다.`);
        await this.load();
    }

    public async log(id) {
        const { code, data } = await wiz.call('log', { id });
        if (code !== 200) {
            await this.service.alert.show("ERROR: Log load failed");
            return;
        }
        await wiz.connect("component.modal.app.log")
            .data({
                logs: data,
            })
            .event("modal-show");
    }

    public async alert(title: string, message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async restore(id) {
        let res = await this.alert("어플리케이션 복구", "어플리케이션을 복구하시겠습니까?", "success", "취소", "복구");
        if (res) {
            const { code, data } = await wiz.call("restore", { app_id: id });
            location.reload();
        }
    }

    public async remove(id) {
        let res = await this.alert("어플리케이션 삭제", "어플리케이션을 정말 삭제하시겠습니까?", "error", "취소", "삭제");
        if (res) {
            const { code, data } = await wiz.call("remove", { app_id: id });
            location.reload();
        }
    }
}

export default ComponentDeveloperListComponent;