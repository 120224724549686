import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.detail.review');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-detail-review',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.detail.review/view.scss */
.new-star {
  display: inline-block;
  direction: rtl;
}
.new-star .selected,
.new-star .selected ~ label {
  color: #fff58c;
}

.new-star label {
  color: #f0f0f0;
  cursor: pointer;
}

.new-star label:hover,
.new-star label:hover ~ label {
  color: gold;
}

textarea {
  overflow: auto;
  overflow-wrap: break-word;
  resize: none;
  height: 100px;
  scrollbar-width: none;
  line-height: 1.5em;
}
textarea::-webkit-scrollbar {
  display: none;
}`],
})
export class ComponentDetailReviewComponent implements OnInit {
    @Input() app: any;
    public star;
    public text = "";
    public list = [];

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    constructor(@Inject( Service)         public service: Service,    ) { }

    public color(item) {
        if (item === this.star) return "selected"
        return ""
    }
    public async load() {
        const { code, data } = await wiz.call("reviews", { id: WizRoute.segment.id })
        if (code !== 200) {
            alert("ERROR: Load reviews failed");
            // await this.service.loading.hide();
            throw new Error("load reviews failed");
        }
        this.list = data;
        await this.service.render();
    }
    public async create() {
        if (this.star === 0) {
            alert("ERROR: 별점을 입력해주세요.");
            throw new Error("new_review star empty");
        }
        if (this.text === "") {
            alert("ERROR: 리뷰를 입력해주세요.");
            throw new Error("new_review content empty");
        }
        const review = JSON.stringify({ app_id: this.app.info.id, rate: this.star, text: this.text });
        const { code } = await wiz.call("create", { review });
        if (code !== 200) {
            alert("ERROR: Review create failed");
            throw new Error("new_review failed");
        }
        this.star = 0;
        this.text = "";
        await this.load();
    }
    public change(item){
        this.star=item;
        this.service.render();
    }
}

export default ComponentDetailReviewComponent;