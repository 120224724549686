import { Injectable } from '@angular/core';
import Alert from './alert';
import Editor from './editor';
import Event from './event';
import File from './file';
import Loading from './loading';
import Menu from './menu';
import Shortcut from './shortcut';

import toastr from "toastr";

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-top-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": 300,
    "hideDuration": 500,
    "timeOut": 1500,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

@Injectable({ providedIn: 'root' })
export class Service {
    public alert: Alert;
    public editor: Editor;
    public event: Event;
    public file: File;
    public loading: Loading;
    public leftmenu: Menu;
    public shortcut: Shortcut;
    public app: any;

    constructor() { }

    public async init(app: any, unique_key: string, version: string = "dev") {
        if (app) {
            this.unique_key = unique_key;
            this.version = version;
            this.base = "/var/www/digitalon/storage/" + this.unique_key + "/apps/" + this.version;
            this.app = app;
            this.alert = new Alert(this);
            this.editor = new Editor(this);
            this.event = new Event(this);
            this.file = new File(this);
            this.loading = new Loading(this);
            this.leftmenu = new Menu(this, 'explore');
            this.shortcut = new Shortcut(this);

            await this.shortcut.bindDefault();
        }
        return this;
    }

    public async log(value: any, tag: string = "ide") {
        const Style = {
            base: [
                "color: #fff",
                "background-color: #444",
                "padding: 2px 4px",
                "border-radius: 2px"
            ],
            ide: [
                "background-color: blue"
            ],
            server: [
                "background-color: green"
            ]
        }

        if (tag == 'server') {
            let style = Style.base.join(';') + ';';
            style += Style.server.join(';');
            if (value.includes(`[build][error]`)) {
                toastr.error(`Build failed`);
            } else if (value.includes(`EsBuild complete in`)) {
                toastr.info(`Builded`);
            }
            console.log(`87line %cwiz.was%c ` + value, style, null);
        } else {
            let style = Style.base.join(';') + ';';
            style += Style.ide.join(';');
            console.log(`91line %cwiz.ide%c`, style, null, value);
        }
    }

    public async render(time: number = 0) {
        let timeout = () => new Promise((resolve) => {
            setTimeout(resolve, time);
        });
        if (time > 0) {
            this.app.ref.detectChanges();
            await timeout();
        }
        this.app.ref.detectChanges();
    }

    public href(url: any) {
        this.app.router.navigate(url);
    }

    public random(stringLength: number = 16) {
        const fchars = 'abcdefghiklmnopqrstuvwxyz';
        const chars = '0123456789abcdefghiklmnopqrstuvwxyz';
        let randomstring = '';
        for (let i = 0; i < stringLength; i++) {
            let rnum: any = null;
            if (i === 0) {
                rnum = Math.floor(Math.random() * fchars.length);
                randomstring += fchars.substring(rnum, rnum + 1);
            } else {
                rnum = Math.floor(Math.random() * chars.length);
                randomstring += chars.substring(rnum, rnum + 1);
            }
        }
        return randomstring;
    }
}

export default Service;
