import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.ide.system.editor');
import { AfterViewInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver, Type } from '@angular/core';
import { Service } from "src/libs/portal/ide/service";

@Component({
    selector: 'wiz-portal-ide-system-editor',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/portal.ide.system.editor/view.scss */
/* src/app/portal.ide.system.editor/view.scss: no such file or directory */`],
})
export class PortalIdeSystemEditorComponent implements AfterViewInit {
    @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
    @Input() editor;

    constructor(@Inject( Service)         public service: Service,@Inject( ComponentFactoryResolver    )         private componentFactoryResolver: ComponentFactoryResolver    ) { }

    public async ngAfterViewInit() {
        let componentClass = this.editor.tab().viewref;
        if (!componentClass) return;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
        const component = this.container.createComponent(componentFactory);
        component.instance.editor = this.editor;
        await this.service.render();
    }
}

export default PortalIdeSystemEditorComponent;