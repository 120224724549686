import Service from './service';
import { KeyMod, KeyCode } from 'monaco-editor';

export default class Shortcut {
    public data: any = [];
    public keymap: any = {};

    constructor(private service: Service) { }

    public bind(namespace: string, value: any) {
        if (this.keymap[namespace]) {
            this.data.remove(this.keymap[namespace]);
        }
        value.allowIn = ['TEXTAREA', 'INPUT', 'SELECT'];
        this.keymap[namespace] = value;
        value.name = namespace;
        this.data.push(value);
        return this;
    }

    public async bindDefault() {
        this.bind("menu.version", {
            key: ["cmd + 1", "ctrl + 1"],
            monaco: KeyMod.CtrlCmd | KeyCode.Digit1,
            preventDefault: true,
            command: async () => {
                await this.service.leftmenu.toggle(this.service.leftmenu.top[0]);
            }
        });

        this.bind("menu.explore", {
            key: ["cmd + 2", "ctrl + 2"],
            monaco: KeyMod.CtrlCmd | KeyCode.Digit2,
            preventDefault: true,
            command: async () => {
                await this.service.leftmenu.toggle(this.service.leftmenu.top[1]);
            }
        });

        this.bind("menu.npm", {
            key: ["cmd + 3", "ctrl + 3"],
            monaco: KeyMod.CtrlCmd | KeyCode.Digit3,
            preventDefault: true,
            command: async () => {
                await this.service.leftmenu.toggle(this.service.leftmenu.top[2]);
            }
        });

        this.bind("menu.preview", {
            key: ["cmd + 4", "ctrl + 4"],
            monaco: KeyMod.CtrlCmd | KeyCode.Digit4,
            preventDefault: true,
            command: async () => {
                await this.service.leftmenu.toggle(this.service.leftmenu.top[3]);
            }
        });

        this.bind("menu.route", {
            key: ["cmd + 5", "ctrl + 5"],
            monaco: KeyMod.CtrlCmd | KeyCode.Digit5,
            preventDefault: true,
            command: async () => {
                await this.service.leftmenu.toggle(this.service.leftmenu.top[4]);
            }
        });

        this.bind("menu.shortcut", {
            key: ["cmd + 6", "ctrl + 6"],
            monaco: KeyMod.CtrlCmd | KeyCode.Escape,
            preventDefault: true,
            command: async () => {
                await this.service.leftmenu.toggle(this.service.leftmenu.top[5]);
            }
        });

        this.bind("exit", {
            key: ["cmd + esc", "ctrl + esc"],
            monaco: KeyMod.CtrlCmd | KeyCode.Escape,
            preventDefault: true,
            command: async () => {
                await this.service.href(["/developer/app/info", {id:this.service.unique_key}]);
            }
        });

        this.bind("save", {
            key: ["cmd + s", "ctrl + s"],
            monaco: KeyMod.CtrlCmd | KeyCode.KeyS,
            preventDefault: true,
            command: async () => {
                await this.service.editor.update();
            }
        });
        this.bind("editor.close", {
            key: ["alt + w"],
            monaco: KeyMod.Alt | KeyCode.KeyW,
            preventDefault: true,
            command: async () => {
                if (this.service.editor.activated)
                    await this.service.editor.activated.close();
            }
        });
        this.bind("editor.clone", {
            key: ["alt + t"],
            monaco: KeyMod.Alt | KeyCode.KeyT,
            preventDefault: true,
            command: async () => {
                if (!this.service.editor.activated) return;
                let target = this.service.editor.activated;
                let location = this.service.editor.indexOf(target);
                await target.clone(location + 1);
            }
        });
        this.bind("editor.minify", {
            key: ["alt + m"],
            monaco: KeyMod.Alt | KeyCode.KeyM,
            preventDefault: true,
            command: async () => {
                if (!this.service.editor.activated) return;
                let target = this.service.editor.activated;
                await target.minify();
            }
        });
        this.bind("editor.tab.prev", {
            key: ["alt + a"],
            monaco: KeyMod.Alt | KeyCode.KeyA,
            preventDefault: true,
            command: async () => {
                if (!this.service.editor.activated) return;
                let target = this.service.editor.activated;
                let current = target.current * 1;
                if (target.tabs[current - 1]) {
                    current = target.current - 1;
                } else {
                    current = target.tabs.length - 1;
                }
                await target.select(current);
            }
        });
        this.bind("editor.tab.next", {
            key: ["alt + s"],
            monaco: KeyMod.Alt | KeyCode.KeyS,
            preventDefault: true,
            command: async () => {
                if (!this.service.editor.activated) return;
                let target = this.service.editor.activated;
                let current = target.current * 1;
                if (target.tabs[current + 1]) {
                    current = target.current + 1;
                } else {
                    current = 0;
                }
                await target.select(current);
            }
        });
    }
}