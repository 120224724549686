import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.modal.app.logs');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-modal-app-logs',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.modal.app.logs/view.scss */
.list-timeline {
  max-height: 800px;
  overflow: auto;
}

.modal {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.53);
}`],
})
export class ComponentModalAppLogsComponent implements OnInit {
    public logs: any;
    public isshow: boolean = false;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async hide() {
        this.isshow = false;
        await this.service.render();
    }
}

export default ComponentModalAppLogsComponent;