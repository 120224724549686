import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.community.edit');
import { OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Service } from "src/libs/portal/season/service";
import ClassicEditor from "src/libs/ckeditor/ckeditor";

@Component({
    selector: 'wiz-component-community-edit',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.community.edit/view.scss */
.ck-editor__editable {
  min-height: 200px;
  width: 100%;
}`],
})
export class ComponentCommunityEditComponent implements OnInit {
    public title: any;
    public category_list = [{ id: "notice", name: "공지사항" }, { id: "issue", name: "이슈 및 추천게시판" }, { id: "qna", name: "문의게시판" }];
    public post = { id: "", category: "", content: "" };
    public editor;
    public fd = new FormData();

    constructor(@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.init();
    }

    public go(item) {
        this.service.href(`/community/${item}/list`);
    }

    public view() {
        this.service.href(`/community/${this.post.category}/view/${this.post.id}`);
    }

    public async init() {
        this.post.id = this.route.params._value.id;
        if (!this.post.id) this.post.id = '';
        this.post.category = WizRoute.segment.category;
        this.title = this.category_list.find(e => e.id === this.post.category).name;
        if (this.post.id !== "new") {
            const { code, data } = await wiz.call("load", { id: this.post.id });
            this.post = data.post;
            await this.service.render();
        }
        const EDITOR_ID = 'textarea#editor';
        this.editor = await ClassicEditor.create(document.querySelector(EDITOR_ID), {
            toolbar: {
                items: 'heading | bold italic strikethrough underline | fontColor highlight fontBackgroundColor | bulletedList numberedList todoList | outdent indent | insertTable imageUpload | link blockQuote code codeBlock'.split(' '),
                shouldNotGroupWhenFull: true
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload/images'
            }
        });
        this.editor.data.set(this.post.content);
        await this.service.render();
    }

    public async update() {
        this.post.id = this.route.params._value.id;
        this.post.category = WizRoute.segment.category;
        let post = this.post;
        post.content = this.editor.data.get();
        const { code, data } = await wiz.call('update', post);
        if (code !== 200) {
            await this.service.alert.show("저장 중 에러가 발생하였습니다.");
            return;
        }
        this.post.id = data;
        this.go(this.post.category);
    }

    public async alert(title: string, message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async communityDel() {
        this.post.id = this.route.params._value.id;
        let res = await this.alert("게시글 삭제", "게시글을 삭제하면 복원할 수 없습니다. 정말 삭제하시겠습니까?", "error", "취소", "삭제");
        if (res) {
            await wiz.call('delete', { id: this.post.id });
            this.go(this.post.category);
        }
    }
}

export default ComponentCommunityEditComponent;