import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.ide.page.ide');
import { Component, Input, OnInit, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/portal/ide/service";
import pluginJson from "src/libs/portal/ide/plugin.json";


@Component({
    selector: 'wiz-portal-ide-page-ide',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/portal.ide.page.ide/view.scss */
.workspace {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.workspace .workspace-body {
  width: 100%;
  display: flex;
  flex: auto;
  overflow: hidden;
}
.workspace .workspace-body .workspace-leftapp,
.workspace .workspace-body .workspace-rightapp {
  height: 100%;
  overflow: hidden;
  position: relative;
}
.workspace .workspace-body .workspace-leftapp.workspace-leftapp,
.workspace .workspace-body .workspace-rightapp.workspace-leftapp {
  width: 240px;
  border-right: 1px solid var(--wiz-color-border);
}
.workspace .workspace-body .workspace-leftapp.workspace-rightapp,
.workspace .workspace-body .workspace-rightapp.workspace-rightapp {
  width: 360px;
  border-left: 1px solid var(--wiz-color-border);
}
.workspace .workspace-body .workspace-editor {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}
.workspace .workspace-body .workspace-editor .editor-list {
  display: flex;
  flex: auto;
  flex-direction: row;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid var(--wiz-color-border);
  min-width: 620px;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body:first-child {
  border-left: none;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-header {
  position: relative;
  display: flex;
  background-color: var(--wiz-color-blue);
  height: 28px;
  align-items: center;
  color: #fff;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-header .header-text {
  max-width: 80%;
  position: absolute;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  font-size: 14px;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-header .header-text small {
  font-size: 12px;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-header .actions {
  margin-left: auto;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-selector {
  background-color: var(--wiz-color-blue);
  height: 28px;
  display: flex;
  align-items: flex-end;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-selector .btn {
  font-size: 11px;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-container {
  flex: auto;
  position: relative;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-container .editor-info {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 24px;
  background-color: var(--wiz-color-light);
  overflow: auto;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-container .editor-info .editor-info-container {
  width: 100%;
  max-width: 640px;
  border-radius: 16px;
  padding: 24px;
  height: fit-content;
  background-color: white;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-container .editor-info .editor-info-container h3 {
  margin-bottom: 24px;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-container .editor-info .editor-info-container .form-group {
  margin-top: 16px;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-header,
.workspace .workspace-body .workspace-editor .editor-list .editor-body .editor-selector {
  opacity: 0.5;
}
.workspace .workspace-body .workspace-editor .editor-list .editor-body.active .editor-header,
.workspace .workspace-body .workspace-editor .editor-list .editor-body.active .editor-selector {
  opacity: 1;
}
.workspace .workspace-body .workspace-editor .editor-minified {
  display: flex;
  background-color: var(--wiz-color-blue-lt);
  width: 100%;
  height: 26px;
  align-items: center;
  overflow: auto;
}
.workspace .workspace-body .workspace-editor .editor-minified .minified-padding {
  margin-left: auto;
}
.workspace .workspace-body .workspace-editor .editor-minified .minified-item {
  flex: 1;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--wiz-color-blue);
  height: 26px;
  display: flex;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 1px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
}
.workspace .workspace-body .workspace-editor .editor-minified .minified-item:hover {
  color: var(--wiz-color-blue);
  background-color: var(--wiz-color-blue-lt);
}
.workspace .workspace-body .workspace-leftmenu,
.workspace .workspace-body .workspace-rightmenu {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 42px;
}
.workspace .workspace-body .workspace-leftmenu .btn,
.workspace .workspace-body .workspace-rightmenu .btn {
  padding: 8px 12px;
  display: block;
  border: none;
  border-radius: 0;
  box-shadow: none;
  height: 32px;
}
.workspace .workspace-body .workspace-leftmenu .btn.active,
.workspace .workspace-body .workspace-rightmenu .btn.active {
  background-color: var(--wiz-color-blue);
  color: #fff;
}
.workspace .workspace-body .workspace-leftmenu .btn .hover-area,
.workspace .workspace-body .workspace-rightmenu .btn .hover-area {
  display: none;
  padding: 8px 20px;
  height: 32px;
  margin-top: -23px;
  min-width: 80px;
  background-color: var(--wiz-color-blue);
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.workspace .workspace-body .workspace-leftmenu .btn .hover-area .sidemenu-icon,
.workspace .workspace-body .workspace-rightmenu .btn .hover-area .sidemenu-icon {
  width: 42px;
  text-align: center;
}
.workspace .workspace-body .workspace-leftmenu .btn:hover .hover-area,
.workspace .workspace-body .workspace-rightmenu .btn:hover .hover-area {
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1000;
}
.workspace .workspace-body .workspace-leftmenu.workspace-rightmenu,
.workspace .workspace-body .workspace-rightmenu.workspace-rightmenu {
  border-left: 1px solid var(--wiz-color-border);
}
.workspace .workspace-body .workspace-leftmenu.workspace-rightmenu .hover-area,
.workspace .workspace-body .workspace-rightmenu.workspace-rightmenu .hover-area {
  padding-right: 0 !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.workspace .workspace-body .workspace-leftmenu.workspace-rightmenu:hover .hover-area,
.workspace .workspace-body .workspace-rightmenu.workspace-rightmenu:hover .hover-area {
  right: 0;
}
.workspace .workspace-body .workspace-leftmenu.workspace-rightmenu:hover .hover-area span,
.workspace .workspace-body .workspace-rightmenu.workspace-rightmenu:hover .hover-area span {
  margin-left: auto;
  margin-right: 4px;
}
.workspace .workspace-body .workspace-leftmenu.workspace-leftmenu,
.workspace .workspace-body .workspace-rightmenu.workspace-leftmenu {
  border-right: 1px solid var(--wiz-color-border);
}
.workspace .workspace-body .workspace-leftmenu.workspace-leftmenu .hover-area,
.workspace .workspace-body .workspace-rightmenu.workspace-leftmenu .hover-area {
  padding-left: 0 !important;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.workspace .workspace-body .workspace-leftmenu.workspace-leftmenu:hover .hover-area,
.workspace .workspace-body .workspace-rightmenu.workspace-leftmenu:hover .hover-area {
  left: 0;
}
.workspace .workspace-body .workspace-leftmenu.workspace-leftmenu:hover .hover-area span,
.workspace .workspace-body .workspace-rightmenu.workspace-leftmenu:hover .hover-area span {
  margin-left: 4px;
}

.editor-overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0);
}

.workspace-editor:has(.sortable-chosen) .editor-container .editor-overlay {
  display: block;
}`],
})
export class PortalIdePageIdeComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() unique_key: string;
    @Input() version: string = "dev";

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef)         public ref: ChangeDetectorRef,@Inject( Router    )         public router: Router    ) { }

    public async ngOnInit() {
        await this.service.init(this, this.unique_key, this.version);
        this.service.leftmenu.build(pluginJson.main, pluginJson.sub);
        window.addEventListener("beforeunload", this.beforeunload, { capture: true });
    }

    public ngOnDestroy() {
        window.removeEventListener("beforeunload", this.beforeunload, { capture: true });
    }

    public async beforeunload(e) {
        e.preventDefault();
        return (e.returnValue = "Are you sure you want to leave the page?");
    };

    public async ngAfterViewInit() {
        let socket = wiz.socket();

        socket.on("connect", async () => {
            socket.emit("join", { id: wiz.branch() });
        });

        socket.on("log", async (data) => {
            this.service.log(data, "server");
        });

    }
}

export default PortalIdePageIdeComponent;