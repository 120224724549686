import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.developer.share');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import toastr from "toastr";

@Component({
    selector: 'wiz-component-developer-share',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.developer.share/view.scss */
.input-button {
  margin-top: 12%;
}`],
})
export class ComponentDeveloperShareComponent implements OnInit {
    @Input() title: any;
    public app_id = WizRoute.path.split("id=")[1];
    public page = {
        start: 1,
        end: 1,
        current: 1,
    };
    public text: string = "";
    public list = [];

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.pageLoad(1);
        await this.service.render();
    }

    private pageLoad(p: number) {
        this.page.current = p;
        this.load();
    }

    public async load() {
        const app_id = this.app_id;
        let body = {
            page: 1,
            app_id
        };
        if (this.text.replace(/\s/g, "").length > 0) body.text = text;
        // if (body.text.replace(/\s/g, "").length === 0) delete body.text;
        const { code, data } = await wiz.call("load", body);
        if (code !== 200) {
            toastr.error("FAILED");
            return;
        }
        const { list, lastpage } = data;
        this.page.start = (parseInt(this.page.current / 11) * 10) + 1;
        this.page.end = lastpage;
        this.list = list;
        await this.service.render();
    }

    public async update(email) {
        const app_id = this.app_id;
        // await this.service.loading.show();
        const body = {
            app_id,
            email,
        }
        const { code } = await wiz.call("update", body);
        if (code !== 200) {
            toastr.error("FAILED");
        }
        toastr.success("SUCCESS");
        await this.load();
    }

}

export default ComponentDeveloperShareComponent;