import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.ide.app.explore');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/ide/service";

import { FlatTreeControl } from '@angular/cdk/tree';
import { FileNode, FileDataSource } from "src/libs/portal/ide/explore";
import { Workspace } from "src/libs/portal/ide/app";



@Component({
    selector: 'wiz-portal-ide-app-explore',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/portal.ide.app.explore/view.scss */
.side-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.side-heading {
  display: flex;
  padding: 8px 16px;
  background: var(--wiz-color-blue-lt);
  color: var(--wiz-color-blue);
  align-items: center;
}
.side-heading i {
  width: 14px;
  text-align: center;
}
.side-heading .actions {
  margin-left: auto;
}
.side-heading .actions > * {
  margin-left: 6px;
  min-width: 24px;
  height: 24px;
}

.side-search {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid var(--wiz-color-border);
}
.side-search label.form-check.form-switch {
  margin: 0;
}

.side-body {
  flex: auto;
  width: 100%;
  overflow: auto;
  padding: 12px;
}
.side-body tr,
.side-body td {
  vertical-align: middle;
}

.file-tree .tree-icon {
  width: 20px;
  text-align: center;
}
.file-tree .mat-tree-node {
  cursor: pointer;
}
.file-tree .mat-tree-node i.icon-action-always {
  margin-left: 8px;
  color: var(--wiz-color-blue);
}
.file-tree .mat-tree-node i.icon-action {
  display: none;
  margin-left: 8px;
}
.file-tree .mat-tree-node.active {
  color: var(--wiz-color-blue);
  background: var(--wiz-color-blue-lt);
}
.file-tree .mat-tree-node:hover {
  color: var(--wiz-color-blue);
  background: var(--wiz-color-blue-lt);
}
.file-tree .mat-tree-node:hover i.icon-action {
  display: inline-block;
  color: var(--wiz-color-gray);
}
.file-tree .mat-tree-node:hover i.icon-action:hover {
  color: var(--wiz-color-blue);
}
.file-tree .form-control {
  height: 20px !important;
  font-size: 12px;
  border-radius: 4px;
  text-align: left;
}

.dropdown .btn::after {
  display: none;
}

.file-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
}

.d-flex {
  align-items: center;
}`],
})
export class PortalIdeAppExploreComponent implements OnInit {
    public path: string;
    public unique_key: string;
    public version: string;

    public isFolder = (_: number, node: FileNode) => node.type == 'folder';
    public isNew = (_: number, node: FileNode) => node.type == 'new.folder' || node.type == 'new.file';
    public isMod = (_: number, node: FileNode) => node.type.substring(0, 3) == 'mod';

    public rootNode: FileNode;
    private treeControl: FlatTreeControl<FileNode>;
    private dataSource: FileDataSource;
    private getLevel = (node: FileNode) => node.level;
    private isExpandable = (node: FileNode) => node.extended;

    constructor(@Inject( Service) private service: Service) {
        this.workspace = new Workspace(service, wiz);
        this.unique_key = this.service.unique_key;
        this.version = this.service.version
    }

    public async ngOnInit() {
        this.path = this.service.base + "/src";
        this.rootNode = new FileNode('root', this.path, 'folder');
        this.treeControl = new FlatTreeControl<FileNode>(this.getLevel, this.isExpandable);
        this.dataSource = new FileDataSource(this);
        let data = await this.list(this.rootNode);
        this.dataSource.data = data;

        this.service.event.bind(wiz.namespace, this);
    }

    public async list(node: FileNode) {
        let { data } = await wiz.call("list", { path: node.path });
        data = data.map(item => new FileNode(item.name, item.path, item.type, node, node.level + 1, item.meta ? item.meta : {}));
        data.sort((a, b) => {
            if (node.level === -1)
                return 0;
            if (a.type == b.type)
                return a.path.localeCompare(b.path);
            if (a.type == 'folder') return -1;
            if (b.type == 'folder') return 1;
        });
        return data;
    }

    public async upload(node: FileNode | null, mode: string = 'file') {
        if (!node) node = this.rootNode;

        let fd = new FormData();
        let files: any = null;

        if (mode == 'app') {
            files = await this.service.file.select({ accept: '.wizapp' });
        } else {
            files = await this.service.file.select();
        }
        let filepath = [];
        for (let i = 0; i < files.length; i++) {
            if (!files[i].filepath) files[i].filepath = files[i].name;
            fd.append('file[]', files[i]);
            filepath.push(files[i].filepath);
        }
        fd.append("filepath", JSON.stringify(filepath));
        fd.append("path", node.path);
        // await this.service.file.upload('wiz/api/portal.ide.app.explore/upload', fd)

        if (mode == 'app') {
            await this.service.file.upload(wiz.url("upload_app"), fd)
        } else {
            await this.service.file.upload(wiz.url("upload"), fd)
        }

        await this.refresh(node);
    }
    
    public async move(node: FileNode) {
        let { rename, path } = node;
        let name = path.split("/");
        name = name[name.length - 1];
        if (name == rename) {
            node.editable = false;
            return false;
        }

        let to: any = path.split("/");
        to[to.length - 1] = rename;
        to = to.join("/");
        let parent_path = to.split("/").slice(0, to.split("/").length - 1).join("/");

        let { code } = await wiz.call("move", { path, to });

        if (code !== 200) {
            await this.service.alert.error("Error on change path");
            return false;
        }

        node.parent = null;
        for (let i = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].path == parent_path) {
                node.parent = this.dataSource.data[i];
                break;
            }
        }

        await this.dataSource.delete(node);
        await this.refresh(node.parent);
        return true;
    }

    public async create(node: FileNode | null, type: any) {
        console.log(node)
        if (!node) {
            return;
        }

        if (node.type == "new.folder" || node.type == "new.file") {
            let type = node.type.split(".")[1];
            let path = node.path + "/" + node.name;
            let { code } = await wiz.call("create", { type, path });

            if (code != 200) {
                await this.service.alert.error("invalid filename");
                return;
            }

            await this.dataSource.delete(node);
            await this.refresh(node.parent);
        } else if (type == "mod.app") {
            let mode = node.type.split(".")[1];
            let app = { mode: mode, id: '', title: '', namespace: '', viewuri: '', category: '', path: this.path };
            await this.workspace.AppEditor(app).open();
        }
        else {
            if (!this.treeControl.isExpanded(node))
                await this.dataSource.toggle(node, true);
            let newitem = new FileNode('', node.path, 'new.' + type, node, node.level + 1);
            await this.dataSource.prepend(newitem, node);
        }
    }

    public async download(node: FileNode | null) {
        if (!node) node = this.rootNode;
        console.log(node.path)
        // let target = wiz.url("download?unique_key=" + this.unique_key + "&version=" + this.version);
        // if (node.type == "app") {
        //     target = "/wiz/api/portal.ide.editor.info/download?unique_key=" + this.unique_key + "&version=" + this.version + "&app_id=" + node.meta.id;
        // }

        let target = wiz.url("download" + node.path);
        window.open(target, '_blank');
    }

    public async remove(node: FileNode, forced: boolean = false) {
        if (node.type != "new.folder" && node.type != "new.file") {
            if (!forced) {
                let res = await this.service.alert.show({ title: 'Delete', message: '삭제 전 등록된 Route를 확인해주세요.', action_text: "Delete", action_class: "btn-danger", cancel: "cancel" });
                if (!res) return;
            }
            await wiz.call("delete", { path: node.path });
        }
        await this.dataSource.delete(node);
    }

    public async open(node: FileNode, location: number = -1) {
        if (!node) return;
        if (node.editable) return;
        if (node.type.split(".")[0] == "new") return;

        let openEditor = {
            app: async () => {
                let app = node.meta;
                await this.workspace.AppEditor(app).open(location);
            },
            default: async () => {
                let path = node.path;
                let editor = this.workspace.FileEditor(path);
                if (editor) {
                    await editor.open(location);
                } else {
                    await this.download(node);
                }
            }
        }

        if (openEditor[node.type]) await openEditor[node.type]()
        else await openEditor.default();
    }

    public active(node: FileNode | null) {
        try {
            if (this.service.editor.activated) {
                let targetpath = this.service.editor.activated.tab().path;
                if (targetpath.split("/").reverse()[1] == node.path.split("/").reverse()[0]) {
                    return true;
                }
            }
        } catch (e) { }
        return false;
    }

    public drag(event: any, node: any) {
        event.dataTransfer.setData("text", node.meta.template);
    }

    // method in libs/app.ts
    public async refresh(node: FileNode | null = null) {
        if (node) {
            await this.dataSource.toggle(node, false);
            await this.dataSource.toggle(node, true);
        } else {
            let data = await this.list(this.rootNode);
            this.dataSource.data = data;
        }
    }
    public find(path: string) {
        let data = this.dataSource.data;
        for (let i = 0; i < data.length; i++) {
            if (data[i].path == path)
                return data[i];
        }
        return null;
    }
}

export default PortalIdeAppExploreComponent;