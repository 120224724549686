import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.user.aside');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Menu } from "src/libs/menu";
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
    selector: 'wiz-component-user-aside',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.user.aside/view.scss */
.card {
  height: auto;
  overflow: hidden;
}

.card-menu {
  padding: 0;
  flex: auto;
  overflow: auto;
}
.card-menu .card-body {
  font-size: 16px;
  padding: 0;
  color: var(--wiz-color-text) !important;
}
.card-menu .card-body a {
  display: block;
  height: 100%;
  padding: 12px 16px;
  color: var(--wiz-color-text) !important;
}
.card-menu .card-body a.check {
  background: var(--wiz-color-blue-lt);
  color: var(--wiz-color-blue) !important;
}
.card-menu .card-body a.check a {
  color: var(--wiz-color-blue) !important;
}`],
})
export class ComponentUserAsideComponent implements OnInit {
    public session = {};
    public list: [];

    constructor(@Inject( Service)         public service: Service,@Inject( Menu)         public menu: Menu,@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Router)         public router: Router,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.setting();
        this.route.params.subscribe(async ({ id }) => {
            await this.setting();
        })
        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                // Hide progress spinner or progress bar
                await this.setting();
            }
        })
        await this.load();
        await this.service.render();
    }

    public async load() {
        this.session = this.service.auth.session;
    }

    public async setting() {
        const currentRoute = WizRoute.path.split("/");
        let role = currentRoute[0]
        let category = currentRoute[1]
        if ((role !== "admin") && (role !== "developer")) {
            category = role;
            role = "user";
        }
        this.list = this.menu.mode[role][category]
        await this.service.render();
    }

    public isActive(link: string) {
        if (location.pathname.split("/")[2] == "notice")
            return link.startsWith("/community/notice");
        if (location.pathname.split("/")[2] == "issue")
            return link.startsWith("/community/issue");
        if (location.pathname.split("/")[2] == "qna")
            return link.startsWith("/community/qna");
        return link.startsWith(location.pathname.split("/").splice(0, 3).join("/"));
    }
}

export default ComponentUserAsideComponent;