import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.developer');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { Menu } from "src/libs/menu";

@Component({
    selector: 'wiz-page-developer',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/page.developer/view.scss */
/* src/app/page.developer/view.scss: no such file or directory */`],
})
export class PageDeveloperComponent implements OnInit {
    public session = {};
    public category: string = null;
    public mode: string = null;
    public url;
    public id: string = null;

    constructor(@Inject( Service)         public service: Service,@Inject( Menu)         public menu: Menu,@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Router)         public router: Router,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(["admin", "developer"]);
        this.category = WizRoute.segment.category;
        this.mode = WizRoute.segment.mode;
        this.url = window.location.href;
        this.id = this.url.split("id=")[1];
        if (this.id === undefined) {
            this.id = WizRoute.path.split("id=")[1];
        }
        // await this.service.render();
        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                this.category = WizRoute.segment.category;
                this.mode = WizRoute.segment.mode;
            }
        })
    }
}

export default PageDeveloperComponent;