import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.main.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-main-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.main.list/view.scss */
.scroll {
  overflow: auto;
  scrollbar-width: none;
}
.scroll::-webkit-scrollbar {
  display: none;
}

.card-header.category {
  margin: 0.75rem 1rem;
  padding: 0;
  border: 0;
}

.form-selectgroup-label {
  box-shadow: none !important;
}

.list-group-item {
  background-color: white;
}
.list-group-item:hover {
  background-color: rgba(27, 125, 241, 0.02);
}

.form-selectgroup-label {
  color: black;
  border: none;
  border-radius: 20px;
  font-size: 16px;
}
.form-selectgroup-label:hover {
  background-color: rgba(0, 0, 255, 0.1);
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  color: white;
  background-color: var(--wiz-color-blue);
  border: none;
}

.form-control:focus {
  border-color: red;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

.form-selectgroup-input:focus + .form-select-label {
  background-color: aqua !important;
  outline: none;
  box-shadow: none;
}

.app-list {
  border: 0;
  background-color: transparent;
}

.row.row-cards {
  position: relative;
}
.row.row-cards > .text-muted {
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-module {
  display: flex;
  width: 260px;
  height: 280px;
  position: relative;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-radius: 16px;
  overflow: hidden;
}
.card-module .thumbnail {
  position: relative;
  background: #f2f3f4;
  overflow: visible;
  height: 200px;
}
.card-module .thumbnail img {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  max-height: 200px;
}
.card-module .thumbnail .category {
  position: absolute;
  top: 180px;
  left: 0;
  z-index: 1;
  background: #3945d0;
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
  font-family: "wiz-r";
  text-transform: uppercase;
  transition: all 0.1s linear;
}
.card-module .thumbnail .featured {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  font-size: 14px;
  font-family: "wiz-eb";
  color: white;
  transition: all 0.1s linear;
  background: red;
  text-align: right;
  padding: 5px 8px;
  user-select: none;
  border-bottom-left-radius: 16px;
}
.card-module .post-content {
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
  transition: all 0.1s linear;
  height: 80px;
  cursor: pointer;
  overflow: hidden;
}
.card-module .post-content .description {
  display: none;
  color: #666;
  font-size: 14px;
  line-height: 1.8em;
}
.card-module .post-content .post-meta {
  padding-top: 5px;
}
.card-module:hover {
  box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
}
.card-module:hover .category {
  display: none;
}
.card-module:hover .post-content {
  display: flex;
  flex-direction: column;
  height: 280px;
  padding-top: 33px;
  padding-bottom: 30px;
}
.card-module:hover .post-content .description {
  flex: 1;
  display: block;
  padding-bottom: 25px;
  overflow-y: auto;
  opacity: 1;
}
.card-module:hover .post-content .post-meta {
  position: absolute;
  bottom: 10px;
}`],
})
export class ComponentMainListComponent implements OnInit {
    @Input() category: any;
    @Input() apps: any;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        // await this.category_handle();
        // await this.apps_handle();
    }

    public async apps_load(category = 'total') {
        // await this.service.loading.show();
        let body = this.apps.query;
        body.category = category;
        const { code, data } = await wiz.call("search", body);
        // await this.service.loading.hide();
        if (code !== 200) return;
        this.apps.list = data;
        await this.service.render();
    }
}

export default ComponentMainListComponent;