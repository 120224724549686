import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.developer.aside');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'wiz-component-developer-aside',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.developer.aside/view.scss */
.navbar-vertical {
  overflow: auto;
  position: relative;
  height: 100%;
}

.sidebar-right {
  border-right: solid 1px #DBDFE5;
}

li.nav-item.active {
  border-left: solid 3px var(--wc-blue) !important;
}
li.nav-item.active .nav-link {
  color: var(--wc-blue) !important;
  font-weight: 1000;
}
li.nav-item.active i {
  color: var(--wc-blue);
}

.navbar-brand-image {
  height: 40px;
  margin-top: 16px;
  margin-bottom: 12px;
}`],
})
export class ComponentDeveloperAsideComponent implements OnInit {
    public list;
    public url;
    public id: string = null;
    public app;

    constructor(@Inject( Service)         public service: Service,@Inject( ActivatedRoute)         public route: ActivatedRoute,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.url = window.location.href;
        this.id = this.url.split("id=")[1];
        if (this.id === undefined) {
            this.id = WizRoute.path.split("id=")[1];
        }
        if (this.id) {
            await this.load_app(this.id)
        }
        await this.service.render();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public async setId() {
        await this.service.render();
        this.url = window.location.href;
        this.id = this.url.split("id=")[1];
        if (this.id === undefined) {
            this.id = WizRoute.path.split("id=")[1]
        }
    }

    public async load_app(id) {
        let { code, data } = await wiz.call("app_info", { app_id: id });
        if (code != 200) {
            location.href = "/developer";
            return;
        }
        this.app = data;
        await this.service.render();
    }


}

export default ComponentDeveloperAsideComponent;