import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.developer.shared');
import { OnInit, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-developer-shared',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.developer.shared/view.scss */
.app-shared {
  width: 100%;
  overflow: hidden;
  display: flex;
  max-height: 100%;
}
.app-shared .list-group {
  flex: auto;
  overflow: auto;
}
.app-shared .list-group-item {
  display: flex;
  background-color: white;
}
.app-shared .list-group-item:hover {
  background-color: rgba(27, 125, 241, 0.02);
}
.app-shared .list-group-item .featured-image {
  width: 200px;
  height: 150px;
}
.app-shared .list-group-item .app-info {
  flex: 1;
  overflow: hidden;
  padding: 12px 16px;
  margin-left: 16px;
}`],
})
export class ComponentDeveloperSharedComponent implements OnInit {
    public list: any = null;
    public status: any = "all"

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public async showLog(id) {
        this.logLoadById(id);
        this.log.isshow = true;
        await this.service.render();
    }

    public async load() {
        let body = {};
        if (this.status !== "all") body.status = this.status;
        const { code, data } = await wiz.call("load", body);
        if (code !== 200) {
            await this.service.loading.hide();
            await this.service.alert.show(`ERROR: ${data}`);
            throw new Error("App list load failed");
        }
        this.list = data;
        await this.service.render();
    }

    public async apply(id) {
        await this.service.loading.show();
        const { code } = await wiz.call("apply", { id });
        await this.service.loading.hide();
        if (code !== 200) {
            await this.service.alert.show("ERORR: Apply judge failed");
            return;
        }
        await this.service.alert.show("승인싱청이 완료되었습니다.");
        await this.load();
    }

    public async publish(id, pub) {
        await this.service.loading.show();
        const { code } = await wiz.call("publish", { id, pub });
        await this.service.loading.hide();
        if (code !== 200) {
            await this.service.alert.show("ERROR: publish failed");
            return;
        }
        await this.service.alert.show(`${{ "active": "배포가", "inactive": "비활성이", "private": "삭제가" }[pub]} 완료되었습니다.`);
        await this.load();
    }
}

export default ComponentDeveloperSharedComponent;