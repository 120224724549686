import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.ide.system.leftmenu');
import { OnInit, Input, DoCheck, OnDestroy } from '@angular/core';
import { Service } from "src/libs/portal/ide/service";

@Component({
    selector: 'wiz-portal-ide-system-leftmenu',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/portal.ide.system.leftmenu/view.scss */
/* src/app/portal.ide.system.leftmenu/view.scss: no such file or directory */`],
})
export class PortalIdeSystemLeftmenuComponent implements OnInit, DoCheck, OnDestroy {
    @Input() menu;

    constructor(@Inject( Service) public service: Service) {
    }

    public async ngOnInit() {
        await this.service.init();
    }

    public async ngDoCheck() {
        if (this.menu.mode === "exit") {
            await this.menu.toggle();
            return location.href = `/developer/app/info;id=${this.service.unique_key}`;
        }
    }
    public async ngOnDestroy() {
        await this.service.render();
    }
}

export default PortalIdeSystemLeftmenuComponent;