import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.config.recommend');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import toastr from 'toastr';

@Component({
    selector: 'wiz-component-admin-config-recommend',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.admin.config.recommend/view.scss */
.current {
  background-color: var(--wiz-color-light);
}`],
})
export class ComponentAdminConfigRecommendComponent implements OnInit {
    public list = [];
    public facet = { total: 0, lastpage: 1, pages: [] };
    
    public query = {
        text: "",
        page: 1,
        featured: "all",
    }

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public async load(page) {
        const query = { ...this.query, page, };
        let pd = query;

        let { code, data } = await wiz.call("search", pd)

        if (code === 200) {
            this.facet.total = data.total;
            this.facet.lastpage = data.lastepage;
            this.list = data.list;
            await this.service.render();
        }
    }

    public async search() {
        await this.service.render();
        this.query.page = 1;
        await this.load(this.query.page);
    }

    public async page(page) {
        if (page < 1) {
            toastr.error('첫 페이지 입니다');
            return;
        }
        if (page > this.facet.lastpage) {
            toastr.error('마지막 페이지 입니다');
            return;
        }
        if (this.query.page === page) {
            return;
        }
        this.query.page = page;
        let q = Object.entries(this.query).map(e => e.join('=')).join('&');
        location.href = "?" + q;
    }

    public async update(item) {
        let app = item;
        const { code } = await wiz.call("update", app)
        if (code !== 200) {
            await this.service.alert("추천앱 수정중 에러발생!")
        }
        location.reload();
    }
}

export default ComponentAdminConfigRecommendComponent;