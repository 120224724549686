import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.login');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-login',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/page.login/view.scss */
.login-view {
  background: #6C8DF6;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}
.login-view .logo {
  margin-bottom: 48px;
  text-align: center;
}
.login-view .logo img {
  width: 100%;
  max-width: 280px;
}
.login-view .cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5%;
}
.login-view .cards .card {
  margin: 12px;
  text-align: center;
  width: 100%;
  max-width: 460px;
  display: flex;
}
.login-view .cards .card .card-body {
  padding: 48px;
}
.login-view .cards .card .card-footer {
  background-color: #DEE4F5;
  font-size: 12px;
  border-radius: 0 0 5px 5px;
  padding: 12px 72px;
}
.login-view .addition-info {
  margin-top: 24px;
  font-size: 14px;
  display: flex;
}
.login-view .addition-info a {
  color: var(--wc-text);
}
.login-view .addition-info a:hover {
  color: #6380de;
}
.login-view .underline-text-input-group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
  /* reset input */
}
.login-view .underline-text-input-group input {
  line-height: 36px;
}
.login-view .underline-text-input-group .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: var(--wc-black);
  font-weight: 700;
}
.login-view .underline-text-input-group .underline-text-input-field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--wc-gray);
  outline: 0;
  font-size: 18px;
  color: #707070;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.login-view .underline-text-input-group .underline-text-input-field::placeholder {
  font-size: 13px;
  color: transparent;
}
.login-view .underline-text-input-group .underline-text-input-field:placeholder-shown ~ .underline-text-input-label {
  font-size: 18px;
  cursor: text;
  top: 20px;
  color: var(--wc-gray);
}
.login-view .underline-text-input-group .underline-text-input-field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-color: var(--wc-blue);
}
.login-view .underline-text-input-group .underline-text-input-field:focus ~ .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: var(--wc-blue);
  font-weight: 700;
}
.login-view .underline-text-input-group .underline-text-input-field:required, .login-view .underline-text-input-group .underline-text-input-field:invalid {
  box-shadow: none;
}
.login-view .underline-text-onlyform-input-group {
  position: relative;
  padding: 15px 0 6px 0;
  margin-top: 10px;
  width: 100%;
  font-weight: 700;
  border-width: 3px;
  border-color: var(--wc-gray);
}
.login-view .underline-text-onlyform-input-group input {
  line-height: 36px;
}
.login-view .underline-text-onlyform-input-group .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  font-size: 15px;
  color: #000000;
  font-weight: 700;
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--wc-gray);
  outline: 0;
  font-size: 18px;
  color: #707070;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field::placeholder {
  font-size: 13px;
  color: var(--wc-gray);
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field:focus {
  padding-bottom: 6px;
  font-weight: 300;
  border-width: 3px;
  border-color: var(--wc-blue);
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field:focus ~ .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: var(--wc-blue);
  font-weight: 700;
}
.login-view .error-focus .underline-text-input-field:focus {
  border-color: var(--wc-highlight) !important;
}
.login-view .error-focus .underline-text-input-field:focus ~ .underline-text-input-label {
  color: var(--wc-highlight) !important;
}
.login-view .error-focus .underline-text-input-field:focus::placeholder {
  color: var(--wc-sub-red) !important;
}
.login-view .error-msg {
  color: red;
  font-size: 12px;
}
.login-view .btn {
  padding: 14px 16px;
  border-radius: 10px;
  height: auto;
}

@media (max-width: 768px) {
  .login-view {
    padding: 24px;
    display: block;
  }
  .logo {
    margin-top: 48px;
  }
}`],
})
export class PageLoginComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        let to = "/main";
        if (location.search === "?extlogin=true" && this.service.auth.status) {
            const token = await this.token();
            if (!token) return await this.alert("Token issuance failed.");
            to = `/main?extlogin=true&__token__=${token}`;
        }
        await this.service.auth.allow(false, to);
    }

    public data: any = {
        username: '',
        password: ''
    };

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async login() {
        let user = JSON.parse(JSON.stringify(this.data));
        user.password = this.service.auth.hash(user.password);
        let { code, data } = await wiz.call("login", user);
        if (code !== 200) {
            return await this.alert(data);
        }
        let to = "/main";
        if (location.search === "?extlogin=true") {
            const token = await this.token();
            if (!token) return await this.alert("Token issuance failed.");
            to = `/main?extlogin=true&__token__=${token}`;
        }
        location.href = to;
    }

    public async token() {
        const { code, data } = await wiz.call("tokenizer");
        if (code !== 200) return;
        return data;
    }
}

export default PageLoginComponent;