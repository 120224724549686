import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.mypage.devices');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-mypage-devices',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.mypage.devices/view.scss */
/* src/app/component.mypage.devices/view.scss: no such file or directory */`],
})
export class ComponentMypageDevicesComponent implements OnInit {
    @Input() list: any;
    private O = Object;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public async load() {
        await this.service.loading.show();
        const { code, data } = await wiz.call("devices");
        if (code !== 200) {
            alert("ERROR: devices load failed");
            await this.service.loading.hide();
            throw new Error("get devices failed");
        }
        await this.service.loading.hide();
        this.list = data;
        await this.service.render();
    }

    public async delete(id) {
        const { code } = await wiz.call("remove", { id: id });
        if (code !== 200) {
            alert("ERROR: remove failed");
            return;
        }
        alert("삭제가 완료되었습니다.");
        await this.load();
    }
}

export default ComponentMypageDevicesComponent;