import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.community.list');
import { OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-community-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.community.list/view.scss */
/* Undefined variable.
  ╷
4 │     border: 1px solid $highlight;
  │                       ^^^^^^^^^^
  ╵
  src/app/component.community.list/view.scss 4:23  root stylesheet */`],
})
export class ComponentCommunityListComponent implements OnInit {
    public list: [] = [];
    public page = {
        start: 1,
        end: 1,
        current: 1,
    };
    public text: string = "";
    public category: string;
    public title: string = "";
    public category_list = [{ id: "notice", name: "공지사항" }, { id: "issue", name: "이슈 및 추천게시판" }, { id: "qna", name: "문의게시판" }];

    constructor(@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Service)         public service: Service,    ) { }


    public async ngOnInit() {
        await this.service.init();
        this.route.params.subscribe(async ({ category }) => {
            this.pageLoad(1);
        })
    }

    private async pageLoad(p: number) {
        this.page.current = p;
        this.load(this.page.current);
        await this.service.render();
    }

    public async load(p: number = 1) {
        if (!this.category) this.category = WizRoute.segment.category;
        this.title = this.category_list.find(item => item.id === this.category).name;
        this.page.current = p;
        let body = {
            category: this.category,
            page: this.page.current,
        };
        // if (body.text.replace(/\s/g, "").length === 0) delete body.text;
        const { code, data } = await wiz.call("search", body);
        if (code !== 200) return;
        const { list, lastpage } = data;
        this.page.start = (parseInt(this.page.current / 11) * 10) + 1;
        this.page.end = lastpage;
        this.list = list;
        await this.service.render();
    }
}

export default ComponentCommunityListComponent;