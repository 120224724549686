import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.ide.app.route');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/ide/service";

import toastr from "toastr";

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": true,
    "progressBar": false,
    "positionClass": "toast-top-center",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": 300,
    "hideDuration": 500,
    "timeOut": 1500,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};


@Component({
    selector: 'wiz-portal-ide-app-route',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/portal.ide.app.route/view.scss */
.side-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.side-heading {
  display: flex;
  padding: 8px 16px;
  background: var(--wiz-color-blue-lt);
  color: var(--wiz-color-blue);
  border-bottom: 1px solid var(--wiz-color-border);
}

.side-body {
  flex: auto;
  width: 100%;
  overflow: auto;
  padding: 0;
}
.side-body tr,
.side-body td {
  vertical-align: middle;
}

input.form-check {
  width: 24px;
}

td.border-bottom {
  border-bottom: 1px solid darkgray !important;
}`],
})
export class PortalIdeAppRouteComponent implements OnInit {
    public list = [];
    public components = [];
    public base;
    public version;
    public main = {
        path: "/main",
        component: "component.main",
        widget: false,
        widgetName: "",
    };

    constructor(@Inject( Service) private service: Service) {
        this.base = this.service.base.split("/").slice(5).join("/");
        this.version = this.service.version;
    }

    public async ngOnInit() {
        await this.service.loading.show();
        await this.load();
        await this.service.loading.hide();
    }

    public changeTab(tab) {
        this.tab = tab;
        this.service.render();
    }

    public add() {
        this.list.push({ path: "", component: "", widget: false, widgetName: "" })
        this.service.render();
    }

    public remove(idx) {
        this.list = [
            ...this.list.slice(0, idx),
            ...this.list.slice(idx + 1),
        ];
        this.service.render();
    }

    public async load() {
        const { code, data } = await wiz.call("list", { path: this.base });
        if (code !== 200) {
            toastr.error("Routing Table load failed");
            return;
        }
        const { routes, components, widgets } = data;
        this.components = components;
        const obj = widgets.reduce((acc, w) => {
            return {
                ...acc,
                [w.url]: w,
            }
        }, {});
        if (obj["/main"]) {
            this.main.widget = true;
            this.main.widgetName = obj["/main"].title;
        }
        this.list = routes.slice(1).map(route => {
            if (obj[route.path]) {
                route.widget = true;
                route.widgetName = obj[route.path].title;
            }
            return route;
        });
        await this.service.render();
    }

    public async save() {
        await this.service.loading.show();
        const list = [];
        const widgets = [];
        [this.main, ...this.list].forEach((e) => {
            if (e.path && e.component) {
                if (e.path[0] !== "/") e.path = "/" + e.path;
                list.push({
                    path: e.path,
                    component: e.component,
                });
                if (e.widgetName) {
                    widgets.push({
                        title: e.widgetName,
                        url: e.path,
                    });
                }
            }
        });
        const body = {
            list: JSON.stringify({ routes: list }),
            widgets: JSON.stringify(widgets),
            path: this.base,
        }
        const { code } = await wiz.call("save", body);
        await this.service.loading.hide();
        if (code !== 200) {
            toastr.error("Routing Table save failed");
            return;
        }
        toastr.success("Success");
        await this.service.render();
    }
}

export default PortalIdeAppRouteComponent;