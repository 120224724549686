import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.ide');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-page-ide',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/page.ide/view.scss */
/* src/app/page.ide/view.scss: no such file or directory */`],
})
export class PageIdeComponent implements OnInit {
    public unique_key;
    public version;

    public async ngOnInit() {
        this.unique_key = WizRoute.segment.unique_key;
        this.version = location.href.split("=")[1] || "dev";
    }
}

export default PageIdeComponent;