import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.apps');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-apps',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.admin.apps/view.scss */
.apps-list {
  width: 100%;
  overflow: hidden;
  display: flex;
  max-height: 100%;
}
.apps-list .list-group {
  flex: auto;
  overflow: auto;
}
.apps-list .list-group-item {
  display: flex;
  background-color: white;
}
.apps-list .list-group-item:hover {
  background-color: rgba(27, 125, 241, 0.02);
}
.apps-list .list-group-item .featured-image {
  width: 200px;
  height: 150px;
}
.apps-list .list-group-item .app-info {
  flex: 1;
  overflow: hidden;
  padding: 12px 16px;
  margin-left: 16px;
}

.current {
  background-color: var(--wiz-color-light);
}`],
})
export class ComponentAdminAppsComponent implements OnInit {
    @Input() title: any;
    public list: [] = [];
    public page = {
        start: 1,
        end: 1,
        current: 1,
    };
    public query = { text: "", status: "all" };
    public title: wiz.data.title;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.pageLoad(1);
    }

    private async pageLoad(p: number) {
        this.page.current = p;
        this.load(this.page.current);
        await this.service.render();
    }

    public async search() {
        this.query.page = 1;
        this.load(this.query.page);
    }

    public async load(page) {
        const query = { ...this.query, page, };
        const { code, data } = await wiz.call("apps", query);
        if (code !== 200) {
            await this.service.alert.show("ERROR: Apps load failed");
            await this.service.loading.hide();
            throw new Error("get apps failed");
        }
        const { list, lastpage } = data;
        this.page.start = (parseInt(this.page.current / 11) * 10) + 1;
        this.page.end = lastpage;
        this.list = list;
        await this.service.render();
    }

    public async alert(title: string, message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async restore(id) {
        let res = await this.alert("어플리케이션 복구", "어플리케이션을 복구하시겠습니까?", "success", "취소", "복구");
        if (res) {
            const { code, data } = await wiz.call("restore", { app_id: id });
            location.reload();
        }
    }

    public async remove(id) {
        let res = await this.alert("어플리케이션 삭제", "어플리케이션을 정말 삭제하시겠습니까?", "error", "취소", "삭제");
        if (res) {
            const { code, data } = await wiz.call("remove", { app_id: id });
            location.reload();
        }
    }
}

export default ComponentAdminAppsComponent;