import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.ide.app.shortcut');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/ide/service";

@Component({
    selector: 'wiz-portal-ide-app-shortcut',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/portal.ide.app.shortcut/view.scss */
.side-area {
  display: flex;
  flex-direction: column;
  width: 320px;
  border-left: 1px solid var(--wiz-color-border);
}
.side-area .side-heading {
  display: flex;
  padding: 8px 16px;
  background: var(--wiz-color-blue-lt);
  color: var(--wiz-color-blue);
}
.side-area .side-heading .actions {
  margin-left: auto;
}
.side-area .side-heading .actions > * {
  margin-left: 6px;
  min-width: 24px;
  height: 24px;
}
.side-area .side-body {
  flex: auto;
  overflow: auto;
  padding: 16px;
  background-color: white;
}
.side-area .side-body .shortcut-th {
  width: 85px;
  background-color: var(--wiz-color-blue-lt);
  color: var(--wiz-color-primary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}`],
})
export class PortalIdeAppShortcutComponent implements OnInit {
    public ismac = false;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        let platform = navigator?.userAgentData?.platform || navigator?.platform || "unknown";
        this.ismac = platform.toUpperCase().indexOf("MAC") >= 0;
    }

}

export default PortalIdeAppShortcutComponent;