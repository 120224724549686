import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.detail');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-detail',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/page.detail/view.scss */
/* src/app/page.detail/view.scss: no such file or directory */`],
})
export class PageDetailComponent implements OnInit {
    private app = {};

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }
    
    public async load() {
        const { code, data } = await wiz.call("load", { app_id: WizRoute.segment.id });
        if (code !== 200) {
            alert("ERROR: Load user failed");
            // await this.service.loading.hide();
            throw new Error("app load failed");
        }
        const { info, user, developer } = data;
        info.star = parseFloat(info.star)
        this.app.info = info;
        this.app.info.install = info.install < 11 ? info.install : parseInt(info.install / 10) + "0 +"
        this.app.user = user;
        this.app.developer = developer;
        await this.service.render();
    }
}

export default PageDetailComponent;