import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.card');
import { Input } from '@angular/core';

@Component({
    selector: 'wiz-component-card',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.card/view.scss */
/* src/app/component.card/view.scss: no such file or directory */`],
})
export class ComponentCardComponent {
    @Input() title: string = "Card Title";
    @Input() text: string = "Content";

    constructor() {
    }
}

export default ComponentCardComponent;