import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.ide.app.preview');
import { OnInit, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/ide/service";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'wiz-portal-ide-app-preview',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/portal.ide.app.preview/view.scss */
.side-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.side-heading {
  display: flex;
  padding: 8px 16px;
  background: var(--wiz-color-blue-lt);
  color: var(--wiz-color-blue);
}
.side-heading .actions {
  margin-left: auto;
}
.side-heading .actions > * {
  margin-left: 6px;
  min-width: 24px;
  height: 24px;
}

.side-search {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-bottom: 1px solid var(--wiz-color-border);
}
.side-search input {
  height: 26px;
}
.side-search label.form-check.form-switch {
  margin: 0;
}

.side-body {
  flex: auto;
  width: 100%;
  overflow: hidden;
  padding: 0;
}
.side-body iframe {
  width: 100%;
  height: 100%;
}`],
})
export class PortalIdeAppPreviewComponent implements OnInit {
    @Output() binding = new EventEmitter<any>();
    public url: string;
    public urlSafe: string;
    public unique_key: string;
    public version: string;
    public base: string;

    constructor(@Inject( DomSanitizer) private sanitizer: DomSanitizer,@Inject( Service)  public service: Service) {
        this.unique_key = this.service.unique_key;
        this.version = this.service.version
        this.base = "/widget/" + this.unique_key + "/view/" + this.version;
    }

    public async ngOnInit() {
        await this.move();

        let data = this;
        this.binding.emit({ id: wiz.namespace, data });
    }

    public async move(url: any) {
        await this.service.loading.show();
        if (!url || url === "/") url = "/main";
        else if (url[0] != "/") url = "/" + url;
        this.url = url;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.base + this.url);
        await this.service.loading.hide();
    }

}

export default PortalIdeAppPreviewComponent;