import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.devs');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-devs',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.admin.devs/view.scss */
/* Top-level selectors may not contain the parent selector "&".
  ╷
1 │ & {
  │ ^
  ╵
  src/app/component.admin.devs/view.scss 1:1  root stylesheet */`],
})
export class ComponentAdminDevsComponent implements OnInit {
    public list = [];
    public page = {
        start: 1,
        end: 1,
        current: 1,
    };
    public query = { page: 1, text: "", role: this.role };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.pageLoad(1);
    }

    private async pageLoad(p: number) {
        this.page.current = p;
        this.load(this.page.current);
        await this.service.render();
    }

    public async load(page) {
        const query = { ...this.query, page, };
        let pd = query;

        let { code, data } = await wiz.call("search", pd)
        if (code === 200) {
            const { list, lastpage } = data;
            this.page.start = (parseInt(this.page.current / 11) * 10) + 1;
            this.page.end = lastpage;
            this.list = list;
            await this.service.render();
        }
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async deleteDev(item) {
        const status = await this.service.alert.show({
            title: "개발자 삭제",
            message: item.name + "개발자를 정말 삭제하시겠습니까?",
            cancel: "취소",
            actionBtn: 'error',
            action: "확인",
            status: 'error'
        })
        if (status) {
            let user = item;
            await wiz.call("delete", user)
            location.reload();
        }
    }

    public async search() {
        this.query.page = 1;
        this.load(this.query.page);
    }
}

export default ComponentAdminDevsComponent;