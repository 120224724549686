import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.main.ranking');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-main-ranking',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.main.ranking/view.scss */
.flex-1 {
  flex: 1;
}

.ranking {
  border: 0;
  background-color: transparent;
}
.ranking > .card-header {
  background-color: var(--wiz-color-blue);
  color: white;
  border-radius: 40px;
  padding: 10px 15px;
  margin-top: 8px;
}
.ranking .list-group-item {
  margin-top: 10px;
  cursor: pointer;
  border-radius: 10px;
  background-color: white;
}
.ranking .list-group-item:hover {
  background-color: rgba(0, 0, 255, 0.1);
}
.ranking .list-group-item .avatar {
  background-color: transparent;
}`],
})
export class ComponentMainRankingComponent implements OnInit {
    @Input() ranking: [];

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }

    // public link = async (id) => {
    //     this.service.href(["/apps/info/", id]);
    // }

}

export default ComponentMainRankingComponent;