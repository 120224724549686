import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router, Event } from '@angular/router';

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/page.mypage/view.scss */
/* src/app/page.mypage/view.scss: no such file or directory */`],
})
export class PageMypageComponent implements OnInit {
    public mode: string = null;

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         public router: Router,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.mode = WizRoute.segment.mode;
        this.router.events.subscribe(async (event: Event) => {
            this.mode = WizRoute.segment.mode;
        })
    }
}

export default PageMypageComponent;