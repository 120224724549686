import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.community.post');
import { OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-community-post',
template: templateSource || '',
    styles: [``],
})
export class ComponentCommunityPostComponent implements OnInit {
    public id;
    public title: any;
    public user;
    public category_list = [{ id: "notice", name: "공지사항" }, { id: "issue", name: "이슈 및 추천게시판" }, { id: "qna", name: "문의게시판" }];
    public post = { id: "", category: "", content: "", user: {} };
    public comment = { community_id: this.post.id, content: "" };
    public comments = [];
    public edit_status = {};

    constructor(@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        this.comments.forEach(comment => {
            this.edit_status[comment.id] = false;
        });
    }

    public go(item) {
        this.service.href(`/community/${item}/list`);
    }

    public async load() {
        this.post.id = this.route.params._value.id;
        let { code, data } = await wiz.call('load', { id: this.post.id })
        if (code !== 200) {
            alert("로드실패. 다시 시도해주세요.")
        }
        this.post = data.post;
        this.user = data.user;
        this.comment.community_id = this.post.id;
        this.title = this.category_list.find(e => e.id === this.post.category).name
        await this.load_comment();
        await this.service.render();
    }

    public async load_comment() {
        let { code, data } = await wiz.call('comment', { post_id: this.post.id })
        this.comments = data;
        await this.service.render;
    }

    public async create() {
        let comment = this.comment;
        let { code, data } = await wiz.call('create', comment)
        if (code === 200) this.comment = { post_id: this.post.id, content: "" };
        await this.load();
    };
    public async delete(comment) {
        const status = await this.service.alert.show({
            title: "댓글 삭제",
            message: "댓글을 삭제하면 복원할 수 없습니다. 정말 삭제하시겠습니까?",
            cancel: "취소",
            actionBtn: 'error',
            action: "삭제",
            status: 'error'
        })
        if (status) {
            let { code, data } = await wiz.call('delete', { id: comment.id })
            location.reload();
        }
    };
    public async update(item) {
        let { code, data } = await wiz.call('update', item)
        location.reload();
    };

    public async editStatus(commentId) {
        this.edit_status[commentId] = !this.edit_status[commentId];
        this.service.render();
    }
}

export default ComponentCommunityPostComponent;