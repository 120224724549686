import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.developer.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-developer-info',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.developer.info/view.scss */
/* src/app/component.developer.info/view.scss: no such file or directory */`],
})
export class ComponentDeveloperInfoComponent implements OnInit {
    @Input() title: any;
    public data;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }

    public async load() {
        await this.service.loading.show();
        let { code, data } = await wiz.call("load");
        if (code != 200) {
            await this.service.loading.hide();
            await this.service.alert("Load developer Error");
            return;
        }
        this.data = data;
        await this.service.loading.hide();
    }

    public async update() {
        await this.service.loading.show();
        let userdata = this.data;

        let { code, data } = await wiz.call("update", { data: JSON.stringify(userdata) });
        await this.service.loading.hide();

        if (code === 200) {
            alert("사용자 정보가 갱신되었습니다.", { btn_class: 'btn-primary', btn_action: '닫기', btn_close: null, title: '사용자 정보 변경' });
            location.reload();
            return;
        }
    }
}

export default ComponentDeveloperInfoComponent;