import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.aside');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-admin-aside',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.admin.aside/view.scss */
.navbar-vertical {
  overflow: auto;
  position: relative;
  height: 100%;
}

.sidebar-right {
  border-right: solid 1px #DBDFE5;
}

li.nav-item.active {
  border-left: solid 3px var(--wc-blue) !important;
}
li.nav-item.active .nav-link {
  color: var(--wc-blue) !important;
  font-weight: 1000;
}
li.nav-item.active i {
  color: var(--wc-blue);
}

.navbar-brand-image {
  height: 40px;
  margin-top: 16px;
  margin-bottom: 12px;
}`],
})
export class ComponentAdminAsideComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }
}

export default ComponentAdminAsideComponent;