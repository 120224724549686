import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.developer');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-developer',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/layout.developer/view.scss */
.wiz-page {
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wiz-page .wiz-content {
  display: flex;
  justify-content: center;
  width: 75%;
}

.loading-zone {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
}
.loading-zone .loading-zone-container {
  width: 100%;
  height: 100%;
  position: relative;
}`],
})
export class LayoutDeveloperComponent implements OnInit {
    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default LayoutDeveloperComponent;