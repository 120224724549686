import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.config.system');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-config-system',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.admin.config.system/view.scss */
.info-box {
  border: solid #E6E7E8 1px;
  width: 100%;
  background: #fff;
  padding: 16px;
  font-size: 14px;
  margin: 24px 12px;
  border-radius: 14px;
  flex: auto;
}
.info-box table {
  margin-bottom: 8px;
  width: 100%;
}
.info-box table td {
  padding: 0;
  line-height: 1;
  white-space: nowrap;
}
.info-box table .title {
  font-weight: 800;
}
.info-box table .spare {
  border-bottom: 2px dotted rgba(116, 108, 112, 0.1);
}
.info-box.process-list {
  padding: 0;
  overflow: auto;
}

.w-1 {
  white-space: nowrap;
  text-align: center;
  padding: 0 12px;
}

th {
  text-align: center;
}

th,
td {
  text-align: center;
}

.row.row-deck {
  flex: 1;
  height: 260px;
}`],
})
export class ComponentAdminConfigSystemComponent implements OnInit {
    public deploy = {};
    public apps = {};
    public active = true;
    public data = { cpu: { usage: null }, memory: { usage: null, total: null }, disk: { used: null, total: null } };

    constructor(@Inject( Service)         public service: Service,    ) { }



    public async timer(time) {
        let minute = Math.round(time / 60);
        if (minute == 0) return time + " sec";
        let hour = Math.round(minute / 60);
        if (hour == 0) return minute + " min";
        return hour + " hr"
    }

    public async load() {
        let { code, data } = await wiz.call('health');
        if (code != 200) return;
        const { deploy, sysdata, apps } = data;
        this.deploy = deploy;
        this.data = sysdata;
        this.apps = apps;
        await this.service.render()
    }

    public async ngOnInit() {
        await this.load();
        setInterval(() => {
            if (this.active) {
                this.load();
            }
        }, 1000);
        await this.service.render();
    }
}

export default ComponentAdminConfigSystemComponent;