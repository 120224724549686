import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.developer.app');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-developer-app',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/component.developer.app/view.scss */
.app-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
}
.app-info .card-body {
  flex: auto;
  overflow: auto;
  padding: 24px 32px;
}
.app-info textarea {
  height: auto;
}

.list-two {
  box-sizing: border-box;
  width: 100%;
}
.list-two .radio-first-2 {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  border-radius: 16px 0 0 16px;
}
.list-two .radio-last-2 {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  border-radius: 0 16px 16px 0;
}
.list-two .blind {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}
.list-two .radio-label {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0.4375rem 0.75rem;
  border-radius: inherit;
  border: 1px solid #e6e7e9;
  font-size: 13px;
  line-height: 18px;
  color: #A5A9B1;
  text-align: center;
}
.list-two .radio-label-focus {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0.4375rem 0.75rem;
  border-radius: inherit;
  border: 1px solid #6D8DF6;
  font-size: 13px;
  line-height: 18px;
  color: #6D8DF6;
  text-align: center;
}`],
})
export class ComponentDeveloperAppComponent implements OnInit {
    @Input() id: any;
    public category: [];
    public data = { title: '' };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        let { code, data } = await wiz.call("info", { app_id: this.id });
        if (code != 200) {
            location.href = "/developer";
            return;
        }
        let { appdata, category } = data;
        this.data = appdata;
        this.category = category;
        await this.service.render();
    }

    public async uploadIcon() {
        // if (!this.project.accessLevel(['admin'])) return;

        let icon = await this.service.file.read({ type: 'image', accept: 'image/*', width: 64, quality: 1 });
        this.data.icon = icon;
        await this.service.render();
        console.log(this.data)
    }

    public async removeIcon() {
        this.data.icon = '';
        await this.service.render();
    }

    public async uploadThumbnail() {
        // if (!this.project.accessLevel(['admin'])) return;

        let thumbnail = await this.service.file.read({ type: 'image', accept: 'image/*', width: 400, quality: 1 });
        this.data.thumbnail = thumbnail;
        await this.service.render();
    }

    public async removeThumbnail() {
        this.data.thumbnail = '';
        await this.service.render();
    }

    public async validation() {
        const { title, category, app_type, icon, thumbnail, description } = this.data;

        if (title.replace(/\s/g, "").length < 4) {
            await this.service.alert.error("앱 이름은 공백을 제외하고 4글자 이상이어야 합니다.");
            return false;
        }

        if (!category) {
            await this.service.alert.error("카테고리를 선택해주세요.");
            return false;
        }

        if (!app_type) {
            await this.service.alert.error("작동 방식을 선택해주세요.")
        }

        if (!description || description.length < 10) {
            await this.service.alert.error("어플리케이션 소개글을 10글자 이상 작성해주세요.");
            return false;
        }

        if (!icon) {
            await this.service.alert.error("아이콘을 업로드해주세요.");
            return false;
        }

        if (!thumbnail) {
            await this.service.alert.error("Featured Image를 업로드해주세요.");
            return false;
        }
        return true;
    }

    public async update() {
        let check = await this.validation();
        if (!check) return;
        if (this.data.app_type !== 'hypertext') this.data.app_url = '';
        await this.service.loading.show();
        let { code, data } = await wiz.call("update", { data: JSON.stringify(this.data) });
        if (code === 200) {
            await this.service.loading.hide();
            alert("앱 정보가 갱신되었습니다.", { btn_class: 'btn-primary', btn_action: '닫기', btn_close: null, title: '앱 정보 변경' });
            location.reload();
            return;
        }
    }
}

export default ComponentDeveloperAppComponent;