import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Menu {
    public id;
    public user = [{ title: '앱스토어', url: '/apps/store' }, { title: '커뮤니티', url: '/community/notice/list' }, { title: '마이페이지', url: "/mypage/info" }];
    public developer = [{ title: '개발자 정보', url: '/developer/info/basic' }, { title: '어플리케이션 개발', url: '/developer/apps/list' }]
    public admin = [{ title: '시스템 설정', url: '/admin/config/category' }, { title: '사용자 관리', url: '/admin/user/all' }, { title: '어플리케이션 관리', url: '/admin/apps/all' }]

    public setting(id) {
        this.id = id;
        this.mode.developer.app = [{ title: '어플리케이션 목록', url: '/developer/apps/list' }, { title: '정보관리', url: `/developer/app/info?id=${this.id}` }, { title: '소스코드', url: `/ide/${this.id}` }, { title: '배포요청', url: `/developer/app/process?id=${this.id}` }, { title: '공유하기', url: `developer/app/share?id=${this.id}` }];
    }

    public mode = {
        user: {
            about: [{ title: '소개', url: '/about/introduce' }, { title: '가입방법', url: '/about/signup' }, { title: '이용안내', url: '/about/info' }, { title: '앱설치', url: '/about/install' }, { title: '확장프로그램 사용법', url: '/about/extension' }, { title: '개발자 등록법', url: '/about/developer' }],
            community: [{ title: '공지사항', url: '/community/notice/list', pattern: '/community/notice/' }, { title: '이슈 및 추천게시판', url: '/community/issue/list', pattern: '/community/issue/' }, { title: '문의게시판', url: '/community/qna/list', pattern: '/community/qna/' }],
            mypage: [{ title: '기본정보', url: '/mypage/info' }, { title: '설치한 어플리케이션', url: '/mypage/apps' }, { title: '설치된 기기', url: '/mypage/devices' }],
        },
        developer: {
            info: [{ title: '기본정보', url: '/developer/info/basic' }],
            apps: [{ title: '내가 개발한 앱', url: '/developer/apps/list' }, { title: '공유받은 앱', url: '/developer/apps/shared' }],
            app: [{ title: '어플리케이션 목록', url: '/developer/apps/list' }, { title: '정보관리', url: `/developer/app/info?id=${this.id}` }, { title: '소스코드', url: `/ide/${this.id}` }, { title: '배포요청', url: `/developer/app/process?id=${this.id}` }, { title: '공유하기', url: `developer/app/share?id=${this.id}` }]
        },
        admin: {
            apps: [{ title: '전체보기', url: '/admin/apps/all' }, { title: '승인 요청한 앱', url: '/admin/apps/pending' }, { title: '업데이트 요청한 앱', url: '/admin/apps/updating' }],
            user: [{ title: '사용자 관리', url: '/admin/user/all', pattern: '/admin/user/all' }, { title: '개발자 관리', url: '/admin/user/developers' }],
            config: [{ title: '카테고리 설정', url: '/admin/config/category' }, { title: '추천앱 설정', url: '/admin/config/recommend' }, { title: '시스템 모니터링', url: '/admin/config/system' }],
        }
    }
}

export default Menu;