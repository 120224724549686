import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.store');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-store',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/page.store/view.scss */
.app-store {
  display: flex;
  padding: 0;
}
.app-store .flex-1 {
  flex: 1;
}
.app-store > * {
  padding: 24px 12px;
}
.app-store > .appstore-menu {
  display: flex;
  flex-direction: column;
  width: 300px;
  overflow: hidden;
}
.app-store > .appstore-menu .card {
  height: auto;
  overflow: hidden;
}
.app-store > .appstore-menu .card-body {
  padding: 12px 16px;
}
.app-store > .appstore-menu .card-body.category-header {
  background: var(--wiz-color-light);
  font-size: 14px;
  font-family: "wiz-eb";
  padding: 6px 12px;
}
.app-store > .appstore-menu .category-menu {
  padding: 0;
  flex: auto;
  overflow: auto;
  min-height: 300px;
}
.app-store > .appstore-menu .category-menu .card-body {
  color: var(--wiz-color-text) !important;
}
.app-store > .appstore-menu .category-menu .card-body.category-item {
  font-size: 16px;
  cursor: pointer;
  padding: 12px 16px;
}
.app-store > .appstore-menu .category-menu .card-body.category-item.active {
  background: var(--wiz-color-blue-lt);
  color: var(--wiz-color-blue) !important;
}
.app-store > .appstore-menu .category-menu .card-body.category-item.active:hover {
  background: var(--wiz-color-blue-lt);
  color: var(--wiz-color-blue) !important;
  text-decoration: underline;
}
.app-store > .appstore-menu .category-menu .card-body.category-item:hover {
  color: var(--wiz-color-text) !important;
  text-decoration: underline;
}
.app-store > .appstore-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
}
.app-store > .appstore-body .apps-body {
  flex: auto;
}
.app-store .thumbnail {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 150px;
}
.app-store .thumbnail .featured {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  font-size: 14px;
  font-family: "wiz-eb";
  color: white;
  transition: all 0.1s linear;
  background: red;
  text-align: right;
  padding: 5px 8px;
  user-select: none;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
}
.app-store .description {
  line-height: 20px;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.list-group-item {
  background-color: white;
}
.list-group-item:hover {
  background-color: rgba(27, 125, 241, 0.02);
}

.current {
  background-color: var(--wiz-color-light);
}`],
})
export class PageStoreComponent implements OnInit {
    private session = { name: "", icon: "", id: "", email: "" };
    private extensionInstalled = false;
    private retry = 5;
    public category = [];
    public query = {
        text: "",
        category: "total",
    };
    public total;
    public page = {
        start: 1,
        end: 1,
        current: 1,
    };
    public apps = [];

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.checkExtension();
        // await this.service.loading.show();
        await this.category_load();
        await this.pageLoad(1);
        // await this.service.loading.hide();
    }

    public checkExtension() {
        setTimeout(async () => {
            const chk = document.querySelector("iframe#digital-on") ? true : true;
            this.extensionInstalled = chk;
            if (!chk && retry > 0) {
                retry--;
                checkExtension();
                return;
            }
            await this.service.render();
        }, 600);
    }

    public async category_load() {
        const { code, data } = await wiz.call("category");
        if (code === 200) {
            this.category = data;
        }
        await this.service.render();
    }

    public title(id) {
        const idx = this.category.findIndex(it => it.id === id);
        if (idx < 0) return "";
        return this.category[idx].title;
    }

    private pageLoad(p: number) {
        this.page.current = p;
        this.load(this.page.current);
    }

    public async change_category(c) {
        this.query.category = c;
        await this.load(this.page.current);
    }

    public async load(page = 1) {
        // await this.service.loading.show();
        this.page.current = page;
        let body = {
            ...this.query,
            page: this.page.current,
        }
        if (body.category === "total") delete body.category;
        if (body.category === "featured") {
            delete body.category;
            body.featured = 1;
        }
        if (body.text.replace(/\s/g, "").length === 0) delete body.text;
        const { code, data } = await wiz.call("load", body);
        // await this.service.loading.hide();
        if (code !== 200) return;
        const { list, total, lastpage } = data;
        this.total = total;
        this.page.start = (parseInt(this.page.current / 11) * 10) + 1;
        this.page.end = lastpage;
        this.session = data.session; //test
        this.apps = list;
        await this.service.render();
    }
    
    public async install(id) {
        // await this.service.loading.show();
        const { code } = await wiz.call("install", { id });
        // await this.service.loading.hide();
        if (code !== 200) {
            alert("ERROR: Install app failed");
            return;
        }
        location.reload();
    }

    public async alert(title: string, message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async remove(id) {
        let res = await this.alert("앱 삭제", "앱을 정말 삭제하시겠습니까?", "error", "취소", "삭제");
        if (res) {
            const { code } = await wiz.call("remove", { id });
            if (code !== 204) {
                alert("ERROR: Remove app failed");
                return;
            }
            location.reload();
        }
    }

    public link(id) {
        this.service.href(["/apps/info/", id]);
    }
}

export default PageStoreComponent;