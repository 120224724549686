import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.ide.editor.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/ide/service";

@Component({
    selector: 'wiz-portal-ide-editor-info',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/portal.ide.editor.info/view.scss */
.editor-info {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 24px;
  background-color: var(--wiz-color-light);
  overflow: auto;
}
.editor-info .editor-info-container {
  width: 100%;
  max-width: 640px;
  border-radius: 16px;
  padding: 24px;
  height: fit-content;
  background-color: white;
}
.editor-info .editor-info-container h3 {
  margin-bottom: 24px;
}
.editor-info .editor-info-container .form-group {
  margin-top: 16px;
}

.input-group-text {
  padding: 0 16px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.form-control {
  text-align: left;
  padding: 0 16px;
}`],
})
export class PortalIdeEditorInfoComponent implements OnInit {
    @Input() editor;

    public data: any = {};
    public loading: boolean = true;
    public unique_key: string;
    public version: string;

    constructor(@Inject( Service) public service: Service) {
        this.unique_key = this.service.unique_key;
        this.version = this.service.version
    }

    public async ngOnInit() {
        this.data = await this.editor.tab().data();
        this.loading = false;
        await this.service.render();
    }

    public async download() {
        let target = wiz.url("download?unique_key=" + this.unique_key + "&version=" + this.version + "&app_id=" + this.data.id);
        window.open(target, '_blank');
    }
}

export default PortalIdeEditorInfoComponent;