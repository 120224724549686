import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.none');
import { OnInit, Input, HostListener, ElementRef } from '@angular/core';

@Component({
    selector: 'wiz-layout-none',
template: templateSource || '',
    styles: [`

/* file: /var/www/digitalon/project/main/build/src/app/layout.none/view.scss */
/* src/app/layout.none/view.scss: no such file or directory */`],
})
export class LayoutNoneComponent implements OnInit {
    @Input() title: any;

    @HostListener("wheel", ["$event"])
    public onScroll(event: WheelEvent) {
        // console.log(event)
        // alert("정신체리라")
        // window.onbeforeunload = function() { return "Your work will be lost."; };
        // this.element.nativeElement.scrollLeft += event.deltaY;
    }
    constructor(@Inject( ElementRef) private element: ElementRef) { }
    public async ngOnInit() {
    }
}

export default LayoutNoneComponent;